import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import {  collection, query, onSnapshot } from 'firebase/firestore';
import styled from 'styled-components';
import { Link } from "react-router-dom"
import { useAuth } from '../context/context';




export default function Followers() {

    const [followers, setFollowers] = useState([]);
    const { currentUser, currentUserData } = useAuth();
    const userId = currentUser.email



    useEffect(() => {
        const followingRef = collection(db, 'users', userId, 'followers');
        const followingQuery = query(followingRef);
    
        const unsubscribe = onSnapshot(followingQuery, (snapshot) => {
          const newFollowing = snapshot.docs.map((doc) => doc.id);
          console.log(newFollowing)
          setFollowers(newFollowing);
        });
    
        return () => unsubscribe();
      }, [userId]);

      

 

    return(
    <StyledDiv>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-sm-2'>
        <p>Users following you:</p>

        {currentUserData && followers.length > 0 ? (followers.map((follower, index) => (
            <div key={index}>
              <Link to={`/user/${follower}`}>{follower}</Link>
            </div>
            ))):(<div>you have no followers...</div>)
            }
                 
        </div>
        <div className='col-12 col-sm-10'>
        




        </div>
      </div>
    </div>
  </StyledDiv>
)
}


const StyledDiv = styled.div`

.feedItemWrapper{
  margin-bottom:20px;
}

.likeDislikeWrapper{
  display:flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.map{
  height:50% ;
}

.leaflet-container{
  height:100% ;
  width:100%;
}

.mapWrapper{
  width:100%;
  max-width:350px;
  height:350px;
  @media(max-width:1200px){
    display:none;
  }
}

.mapIconSmallScreen{
  @media(min-width:1200px){
    display:none;
  }
}

.showMapMobile{
  position:relative;
  left:0px;
  top:0px;
  width:300px;
  height:300px;

}

.smallMap{
  display:none ;
}

.mapAndVideoWrapper{
  display: flex;
  width:100% ;
}

.videoPhotoWrapper{
  width:100% ;
}

.videoPhotoWrapperSmall{
  width:50% ;
}



.userWrapper {
    display: flex;
    align-items: center;
    padding: 12px;
  }

  .userProfileWrapper {
    display: flex;
    align-items: center;
    margin-right: 12px;

    .profileImg {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 12px;
    }

    .userName {
      font-weight: bold;
      margin-bottom: 4px;
    }
  }

  .postTags {
    color: #5c5c5c;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .time{
    color: #5c5c5c;
    font-size: 12px;
  }

  .postTags span {
    margin-right: 4px;
  }

  .thumbs{
    position: relative ;
  }

  .likeDislike{
    cursor: pointer;
  }

  .likeDislike:hover{
    color:#0d6efd ;
  }

  .postPhoto{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    margin-bottom:12px;
  }


`;
