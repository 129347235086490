import React, { useState, useRef, useEffect } from 'react';
import { storage } from '../firebase/firebase';
import { collection, addDoc } from "firebase/firestore"; 
import { useAuth } from '../context/context'
import { db } from '../firebase/firebase';
import styled from 'styled-components';
import Geohash from 'latlon-geohash';
import {Link} from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';

import LocationPicker from '../components/LocationPicker';


export default function Admin() {

    const defaultUserImg = "https://firebasestorage.googleapis.com/v0/b/postemic.appspot.com/o/defaultMedia%2FuserDefault.png?alt=media&token=c028efb3-0056-401a-8f2d-e2b3aa8d0dc4"
    const anonymusUserImg = "https://firebasestorage.googleapis.com/v0/b/postemic.appspot.com/o/defaultMedia%2Fanonymus.webp?alt=media&token=9b605109-f21a-4ede-9c17-3f52173f6e3b"
    
    
    
    
    const MAX_VIDEO_LENGTH_SECONDS = 90;
    const MAX_DESCRIPTION_LENGTH = 100;
    const MAX_VIDEO_SIZE = 157286400;


    const { currentUser, currentUserData } = useAuth()

  const [videoFile, setVideoFile] = useState(null);
  const [videoDuration, setVideoDuration] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoDescription, setVideoDescription] = useState("");
  const [videoTags, setVideoTags] = useState("");
  const [videoUrl, setVideoUrl] = useState(null);
  const videoPlayerRef = useRef(null);
  const [anonymousLocation, setAnonymousLocation] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState(null);


  const fileInput = useRef(null);


useEffect(() => {
  const getLocation = () => {
    if (!currentUser) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setAnonymousLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        error => {
          console.error("Error fetching location: ", error);
        },
        { timeout: 10000 } // example option: give up after 10 seconds
      );
    }
  };
  getLocation();
}, []);

    const handleLocationSelect = (latlng) => {
        setSelectedLocation(latlng);
      
    };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    
    // Checking file size
    if (file.size > MAX_VIDEO_SIZE) { 
      return alert("max file size is 150MB");
    }
  
    const reader = new FileReader();
    reader.readAsDataURL(file);
  
    reader.onload = (event) => {
      const fileType = file.type.split("/")[0];
  
      if (fileType === "video") {
        const video = document.createElement('video');
        video.src = event.target.result;
        video.onloadedmetadata = () => {
          setVideoDuration(video.duration);
        };
      } else if (fileType === "image") {
        // Handle image specific logic, if any
        setVideoDuration(null); // Setting to null can indicate no duration, better than 0 for images
      }
    };
  
    setVideoFile(file);
  
    // Creating object URL for displaying the video/image in a preview
    const objectUrl = URL.createObjectURL(file);
    setVideoUrl(objectUrl);
  };
  

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const reader = new FileReader();
  
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const fileType = file.type.split("/")[0];
  
      if (fileType === "video") {
        const video = document.createElement('video');
        video.src = event.target.result;
        video.onloadedmetadata = () => {
          setVideoDuration(video.duration);
        };
      } else if (fileType === "image") {
        // Handle image specific logic, if any
        setVideoDuration(null); // Setting to null for images
      }
    };
  
    setVideoFile(file);
  
    // Creating object URL for displaying the video/image in a preview
    const objectUrl = URL.createObjectURL(file);
    setVideoUrl(objectUrl);
  };
  

  const handleVideoClick = () => {
    if (videoPlayerRef.current.paused) {
      videoPlayerRef.current.play();
    } else {
      videoPlayerRef.current.pause();
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (videoDuration > MAX_VIDEO_LENGTH_SECONDS) {
      alert(`Video must be ${MAX_VIDEO_LENGTH_SECONDS} seconds or less.`);
      return;
    }
    const storageRef = storage.ref();
    const fileExtension = videoFile.name.split('.').pop();
    const uniqueName = `${uuidv4()}.${fileExtension}`;
    const videoRef = storageRef.child(`videos/${uniqueName}`);
    const uploadTask = videoRef.put(videoFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error(error);
      },
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        setUploadProgress(100);
        alert('Video uploaded successfully!');
        console.log(downloadURL);
        await uploadDataToDatabase(downloadURL, uniqueName);
      
        // Navigate to the home page
        window.location.href = "/";

      }
      
    );
  };

  //////////////////////// take one geohash + neighbors and generate a new geohash
  function mergeGeohashesAndNeighbors(centerGeohash) {
    let neighbors = [];
    // Find the eight neighbors of the center geohash
    const centerAndNeighbors = Geohash.neighbours(centerGeohash);
    
    // Add the center geohash to the array of neighbors
    neighbors.push(centerGeohash);
    
    // Add the neighbors to the array
    for (let key in centerAndNeighbors) {
      neighbors.push(centerAndNeighbors[key]);
    }
    return neighbors;
  }

  //////////////////// Get "the box" around geohash
 
function getBoundingBoxFromGeohashes(geohashes) {
  if (!Array.isArray(geohashes)) {
    throw new Error('geohashes must be an array');
  }

  const bbox = geohashes.reduce((acc, geohash) => {
    const { lat, lon } = Geohash.decode(geohash);
    return {
      minLat: Math.min(acc.minLat, lat),
      maxLat: Math.max(acc.maxLat, lat),
      minLng: Math.min(acc.minLng, lon),
      maxLng: Math.max(acc.maxLng, lon),
    };
  }, {
    minLat: 90,
    maxLat: -90,
    minLng: 180,
    maxLng: -180,
  });

  const { minLat, maxLat, minLng, maxLng } = bbox;

  const cbox = [minLat, minLng, maxLat, maxLng];
  console.log(cbox)
  return cbox.flat();
}
  


  const uploadDataToDatabase = async (downloadURL, uniqueName) => {
    const postRef = collection(db, 'posts');
    const timestamp = new Date();
    const lat = selectedLocation.lat || null;
    const lng = selectedLocation.lng || null;
    const precision = 3;
    const geohash = Geohash.encode(lat, lng, [precision]); // use 7 characters for geohash precision
    const newGeohash = mergeGeohashesAndNeighbors(geohash);
    const boundingBox = getBoundingBoxFromGeohashes(newGeohash);
    const minSpreadPower = [3, 6, 9, 12,15,18,21,25,30,35,40,45,50,55,60,65,70,75,80,85,95,100];

   // const estimatedRadius = estimateGeohashRadius(newGeohash)

    const tagsArray = videoTags ? videoTags.split(",") : [];

    await addDoc(postRef, {
      videoUrl: downloadURL,
      postCreatedBy: currentUserData?.userEmail || 'Anonymous',
      creatorId: currentUserData?.uid || null,
      postCreatedByName: currentUserData?.userName || 'Anonymous',
      postCreatedByUserImg: currentUserData 
        ? (currentUserData.userImg || defaultUserImg) 
        : anonymusUserImg,
      timestamp: timestamp,
      description: videoDescription,
      tags: tagsArray,
      lat: lat,
      lng: lng,
      geohash: geohash,
      newGeohash: newGeohash,
      videoFileName: uniqueName,
      likes: 0,
      dislikes: 0,
      likedBy: [],
      dislikedBy: [],
      spreadPower: 0,
      zoom: 6,
      square: boundingBox,
      minSpreadPower: minSpreadPower,
      // radius: estimatedRadius,
    });
    
  };





  const handelAbort=()=>{

    setVideoFile(null);
    setVideoDuration(null);
    setUploadProgress(0);
    setVideoDescription("");
    setVideoTags("");
    setVideoUrl("");
  }

  return (


    <StyledDiv className='container mt-2'>
    <h3>Posting as Admin</h3>

    <LocationPicker onLocationSelect={handleLocationSelect}  />

    <p>{selectedLocation && selectedLocation.lat}</p>
    <p>{selectedLocation && selectedLocation.lng}</p>


    <form onSubmit={handleUpload}>
      <label>
        <input type="file" accept="video/*, image/*" onChange={handleFileSelect} ref={fileInput} style={{ display: 'none' }} />
      </label>
      <button type="button" onClick={() => fileInput.current.click()}>Select a video/photo file</button>


      {videoUrl ? (
<div className="media-preview">
  {videoDuration !== null ? (
    <video
      src={videoUrl}
      onClick={handleVideoClick}
      ref={videoPlayerRef}
    />
  ) : (
    <img className="imgPreview" src={videoUrl} alt="Selected" />
  )}
</div>
) : (
<div onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} className="drop">
  <p style={{ textAlign: 'center' }}>Drop your video/photo here</p>
</div>
)}


      


      {videoFile && (
        <p>
          {videoFile.name} ({Math.round(videoDuration)} seconds)
        </p>
      )}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <progress value={uploadProgress} max="100" />
      )}

      <label>Description:</label>
        <input type="text" value={videoDescription} onChange={(e) => setVideoDescription(e.target.value)} maxLength="100" />

        <label>Tags:</label>
        <span><small>separate tags with comma ","</small></span>
      <input type="text" value={videoTags} onChange={(e) => setVideoTags(e.target.value)} />
                { videoDescription.length > 0 ||  videoFile || videoTags.length > 0 ? (
                  <div className='limitBarsWrapper'>
                          <div>
                    <label className='labelBottom'>Video Size:</label>
                    <progress value={videoFile ? Math.round(videoFile.size / 1024 / 1024) : 0} max="150" style={{ backgroundColor: videoFile && videoFile.size <= MAX_VIDEO_SIZE ? "green" : "red" }} />
                  </div>

                  <div>
                    <label className='labelBottom'>Video Length:</label>
                    <progress value={videoDuration ? Math.round(videoDuration) : 0} max="90" style={{ backgroundColor: videoDuration && videoDuration <= MAX_VIDEO_LENGTH_SECONDS ? "green" : "red" }} />
                  </div>

                  <div>
                    <label className='labelBottom'>Description Length:</label>
                    <progress value={videoDescription.length} max="100" style={{ backgroundColor: videoDescription.length <= MAX_DESCRIPTION_LENGTH ? "green" : "red" }} />
                </div>
                </div>):(<span></span>)}

  <div className='bottomButtons'>
      <button type="reset" onClick={handelAbort} >Abort</button>
      <button type="submit">Upload</button>
  </div>

  {!currentUser && <div className='anonymusDiv'>You are not signed in and will therfore be posting anonymously <img src={anonymusUserImg} className='anonymusImg' alt="anonymusImg"/> Click here to <Link to="/login">here</Link> to sign in</div>
}
      
    </form>
  </StyledDiv>
    
    

  )
}




const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width:500px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    .bottomButtons{
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}
  }

  label {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  @media(max-width:600px){
    .labelBottom{
      font-size: 10px!important;
      font-weight:thin!important;
      white-space:nowrap;
    }
  }

  .anonymusDiv{
    margin-top:25px ;
    text-align: center ;
    font-size:14px ;
  }
  .anonymusImg{
    width: 50px;
    border-radius:50% ;
  }

  input[type="text"] {
    width: 100%;
    padding: 5px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }

  button[type="submit"], button[type="button"] {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    min-width:110px; 
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  button[type="submit"]:hover, button[type="button"]:hover {
    background-color: #555;
  }

  button[type="reset"]{
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f0ad4e;
    color: black;
    border: none;
    min-width:110px; 
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  button[type="reset"]:hover {
    background-color: #d5ad4e;
  }

  progress {
    width: 100%;
    height: 20px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .limitBarsWrapper {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;

    div {
      width: 30%;
      display: flex;
      flex-direction: column;

      label {
        margin-top: 5px;
        font-size: 14px;
      }

      progress {
        margin-top: 5px;
      }
    }
  }

  div[style*="dashed"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    p {
      margin: 0;
    }

    &:hover {
      border-color: #333;
    }
  }

  .drop{
    width:100%;
    height:150px;
    border: 1px dashed gray;
  }
  video{
    width:100%;
    height:auto;
  }

  .imgPreview{
    width:100%;
    height:auto;
  }
`;
