import React, { useState, useEffect } from 'react';
import { db } from "../firebase/firebase";
import {  collection, getDocs, query, orderBy, where, limit, updateDoc, doc} from "firebase/firestore";
import { useAuth } from '../context/context';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faBell, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export default function Notifications() {
    const { currentUserData } = useAuth()
    const [notifications, setNotifications] =useState([])
    const [showOverlay, setShowOverlay] = useState(false);
    const [unseenCount, setUnseenCount] = useState(0);

    useEffect(()=>{
        const fetchNotifications = async () => {
            if (!currentUserData) {
              return;
            }
            const notificationsQuery = query(collection(db, "notifications"), where("userId", "==", currentUserData.userEmail), orderBy('timestamp', 'desc'),limit(15));
            const querySnapshot = await getDocs(notificationsQuery);
            const fetchedNotifications = querySnapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            });
            setNotifications(fetchedNotifications)
            setUnseenCount(fetchedNotifications.filter(notification => !notification.isSeen).length);
        }

        const markAsSeen = async () => {
            const unseenNotifications = notifications.filter(notification => !notification.isSeen);
            unseenNotifications.forEach(async (notification) => {
                const notificationRef = doc(db, 'notifications', notification.id);
                await updateDoc(notificationRef, { isSeen: true });
            });
            setUnseenCount(0);
        }
        if (showOverlay) {
            markAsSeen();
        }
        fetchNotifications()
    },[currentUserData, showOverlay])

    return (
        <StyledDiv>
        <div className="icon-container">
        <FontAwesomeIcon className="icon my-1" icon={faBell} color="gray" size="xl" onClick={() => {setShowOverlay(!showOverlay);}}/>
        {unseenCount > 0 && <span className="count">{unseenCount}</span>}
    </div>
        {showOverlay && (
            <div className="overlay">
                <div className='container'>
                    <FontAwesomeIcon className="closeButton" icon={faTimesCircle} size={"xl"} onClick={()=>setShowOverlay(!showOverlay)}/>
                    <div>
                        {notifications && notifications.map((notification) => (
                            <div className='listItem' key={notification.id} style={{ backgroundColor: notification.isSeen ? 'rgb(245 245 245)' : 'rgb(255 255 245)' }}>
                                <p style={{ color: notification.isSeen ? 'gray' : 'black' }}>{notification.content}</p>
                                <p className="time" style={{ color: notification.isSeen ? 'lightgray' : 'black' }}>
                                {notification.timestamp.toDate().toLocaleString('en-US', {
                                    weekday: 'short',
                                    month: 'short',
                                    day: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: false,
                                })}
                            </p>
                                
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )}
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
.icon:hover{
    cursor:pointer;
    animation: shake 0.3s;
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.icon-container {
    position: relative;
  }

  .count {
    position: absolute;
    top: -5px;
    left: 13px;
    background-color: #F84F51;
    color: white;
    border-radius: 50%;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 0.7em;
    min-width: 1em;
    text-align: center;
  }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 10px; // Added padding to make the overlay more mobile-friendly
}
@media(max-width:700px){
    padding:0px;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  max-width: 600px; // Set a max-width to prevent the container from getting too large on wide screens
  width: 100%; // Set width to 100% to fill the space available on smaller screens
}

.listItem{
    position:relative ;
    border-radius:15px;
    width:100%;
    padding:10px;
    margin-bottom:5px;
    overflow-wrap: break-word; 
    word-wrap: break-word; 
    word-break: break-word;
}
p{
    padding:0px;
    margin:0px;
    font-size:13px;
    word-wrap: break-word;  /* It will break the word at the end of the line */
    overflow-wrap: break-word;  /* Specifies that the browser should insert a line break between any two words if an otherwise unbreakable string is too long to fit within its containing box */
    word-break: break-word;  /* Use this as a last resort, it's more aggressive and can break words unnecessarily */
    
}
.time{
    font-size:11px;
}


`
