import React, {useRef, useState} from 'react'
import { Card, Form, Button, Alert, Spinner  } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from '../context/context'
import { db, gAuth } from "../firebase/firebase"
import { collection, addDoc,setDoc, doc, getDoc} from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider   } from "firebase/auth";
import styled from 'styled-components'





export default function Login() {

    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const auth = getAuth();




    async function handleSubmit(e){
        e.preventDefault()
        try{
                setError("")
                setLoading(true)
                   await login(emailRef.current.value, passwordRef.current.value)
                    // Navigate to the home page
                    window.location.href = "/";
        } catch{
            setError("Failed to log in. Something went horribly wrong")
        }
        setLoading(false)
    }


    const SignInWithGoogle = ()=>{
        setError("")
        setLoading(true)
        const provider = new GoogleAuthProvider();
        signInWithPopup(gAuth, provider)
        .then((re)=>{                    
              // Navigate to the home page
        window.location.href = "/";
              console.log(re)
        })
        .catch((err)=>{
            setError('Failed to log in')
            console.log(err)
        })
        setLoading(false)
    }

{/*
    async function signInWithFacebook() {
        setError("");
        setLoading(true);
        const provider = new FacebookAuthProvider();
        try {
          const result = await signInWithPopup(gAuth, provider);
          navigate('/')
        } catch (error) {
          console.error(error);
          setError('Failed to log in');
        } finally {
          setLoading(false);
        }
      }
      */}

    function reloadPage() {
        window.location.reload();
      }

  return (
    <StyledDiv>
    <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
    <div className="w-100" style={{maxWidth: "400px"}}> 
    <Card>
        <Card.Body>
            <h2 className='text-center mb-4'>Log In</h2>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
                </Form.Group>
                <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
                </Form.Group>
               
                {!loading  ? ( <Button disabled={loading} type="submit" className='w-100 text-center'>Log In</Button>)
                :
                ( loading && error === "User already exists." ? ( <Button onClick={reloadPage}   className='w-100 text-center'>Reload</Button>) : 
  (<Button disabled={loading} className='w-100 text-center'>
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    Loading...
  </Button>
))}


<p><span>Or</span></p>

<button type="button" className="login-with-google-btn" onClick={SignInWithGoogle} disabled={loading}>Sign in with Google</button>
     <br></br>
{/*
<button type="button" className="login-with-facebook-btn" onClick={signInWithFacebook} disabled={loading}>Sign in with Facebook</button>
*/}
    
            </Form>
        </Card.Body>
    </Card>
    <div className='w-100 text-center mt-2'>
    <Link to="/forgotPassword">
                Forgot Password?
    </Link>
        </div>
   
    <div className='w-100 text-center mt-2'>
    <Link to="/signup">
                Dont have and account? Sign Up
    </Link>
        </div>
        </div>
        </div>
  
        </StyledDiv>
  )
}


const StyledDiv = styled.div`

p {
   width: 100%; 
   text-align: center; 
   border-bottom: 1px solid darkgray; 
   line-height: 0.1em;
   margin: 10px 0 20px; 
} 

p span { 
    background:#fff; 
    padding:0 10px; 
}



.login-with-google-btn {
  display: flex;
  justify-content: center;
  margin:auto;
  width:100%;
  transition: background-color .3s, box-shadow .3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #eeeeee;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}

.login-with-facebook-btn {
  display: flex;
  justify-content: center;
  margin:auto;
  width:100%;
  transition: background-color .3s, box-shadow .3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url('https://cdn3.iconfinder.com/data/icons/capsocial-round/500/facebook-512.png');
  background-color: #3b5998;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  
  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }
  
  &:active {
    background-color: #2d4373;
  }
  
  &:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}

`
