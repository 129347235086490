import React, { useState, useEffect, useRef  } from 'react';
import { db } from "../firebase/firebase";
import {  collection, getDocs, query, orderBy, addDoc, updateDoc, doc, increment, deleteDoc} from "firebase/firestore";
import { useAuth } from '../context/context';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPaperPlane, faCircleXmark, faComment, faTrashCan, faEllipsis, faEdit} from '@fortawesome/free-solid-svg-icons'





export default function CommentBox({ postId }) {

  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const { currentUserData } = useAuth()
  const [commentsRan, SetCommentsRan] = useState(false)
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);

  const defaultUserImg = "https://firebasestorage.googleapis.com/v0/b/postemic.appspot.com/o/defaultMedia%2FuserDefault.png?alt=media&token=c028efb3-0056-401a-8f2d-e2b3aa8d0dc4"


  const handleToggleOptions = (commentId) => {
    setActiveCommentId(commentId);
  };
  const handleToggleOptionsClose = ()=>{
    setActiveCommentId(null)
  }

  const textAreaRef = useRef(null);

const handleInputChange = (e) => {
  setComment(e.target.value);
  textAreaRef.current.style.height = "auto";
  textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
};

  useEffect(() => {
    const fetchComments = async () => {
        const commentsRef = collection(db, "posts", postId, "comments");
        const querySnapshot = await getDocs(query(commentsRef, orderBy('timestamp', 'desc')));

        const fetchedComments = querySnapshot.docs.map(doc => {
            return {
                id: doc.id, // you might want the document id as well
                ...doc.data()
            }
        });

        setComments(fetchedComments);
    }
    fetchComments();
}, [postId, commentsRan]);

////////////add comment and edit comment funtionality here
const addComment = async () => {
  if (editingCommentId) {
    // Editing mode, update the comment
    const docRef = doc(db, "posts", postId, "comments", editingCommentId);
    await updateDoc(docRef, { text: comment });
    console.log("Comment update success");
    setEditingCommentId(null);  // Clear the editing mode
    setComment('');
  } else {
    // Normal mode, add a new comment
    const newComment = {
        text: comment,
        userId: currentUserData.userEmail,
        userName: currentUserData.userName || 'Anonymous',
        userImageUrl: currentUserData.userImg || defaultUserImg,
        timestamp: new Date(),
    };

    await addDoc(collection(db, 'posts', postId, 'comments'), newComment);
    setComment('');
  
    // increment commentCount in the post document
    const postRef = doc(db, 'posts', postId);
    await updateDoc(postRef, {
      commentCount: increment(1)
    });
  }

  SetCommentsRan(!commentsRan);
};


const handleDeleteComment = async (commentId, commentUser) => { 
  try {
    if(currentUserData.userEmail === commentUser) {
      const docRef = doc(db, "posts", postId, "comments", commentId)
      await deleteDoc(docRef)
      console.log("comment delete success")

      // decrement commentCount in the post document
      const postRef = doc(db, 'posts', postId);
      await updateDoc(postRef, {
        commentCount: increment(-1)
      });

      SetCommentsRan(!commentsRan)
    } else {
      console.log("You can't delete that comment! Auth error")
      return
    }
  } catch(error) {
    console.log("deletion failed.")
  } 
}


const handleEditComment = (commentId, commentText) => {
  setComment(commentText);
  setEditingCommentId(commentId);
  handleToggleOptionsClose();
};

  return (
    <StyledDiv>
    <div>
    <FontAwesomeIcon className="commentIcon" icon={faComment} color="gray" size="xl" onClick={() => setShowOverlay(!showOverlay)} />
      {showOverlay && (
        <div className="overlay" >
        <div className='container'>
        <FontAwesomeIcon className="closeButton" icon={faCircleXmark} size={"xl"} onClick={()=>setShowOverlay(!showOverlay)}/>
          <div className="comment-input-container">
          <textarea
            ref={textAreaRef}
            maxLength="700"
            type="text"
            placeholder="Write a comment..."
            value={comment}
            onChange={handleInputChange}
          />
            <FontAwesomeIcon className={comment.length > 0 ? ("faPaperPlaneActive") : ("faPaperPlane")} icon={faPaperPlane} onClick={addComment}/>
          </div>
          <div className="comments-list">
  {comments.map((comment, index) => (
    <div key={index} className="comment-item">
            <img src={comment.userImageUrl} alt="" />

      <div className="commentAndName">
        <div>
          <p><strong>{comment.userName}</strong></p>
          <pre>{comment.text}</pre>
        </div>
          <div className="row dateAndSettings">
        <span className="time">{new Date(comment.timestamp.seconds * 1000 + comment.timestamp.nanoseconds / 1000000).toLocaleString()}</span>
        
        {activeCommentId === comment.id ? (<FontAwesomeIcon icon={faCircleXmark} className="commentSettings" onClick={handleToggleOptionsClose} />) : 
        ( comment.userId === currentUserData.userEmail && <FontAwesomeIcon icon={faEllipsis}   className={`commentSettings${activeCommentId === comment.id ? ' active' : ''}`} onClick={() => {handleToggleOptions(comment.id)}} />) 
        }
          {activeCommentId === comment.id && comment.userId === currentUserData.userEmail && (
            <div className="optionsMenu">
            
            <div className='col' onClick={() => { handleEditComment(comment.id, comment.text) }}>
             <span>Edit comment</span><FontAwesomeIcon icon={faEdit} className="commentSettings"  />
            </div>

            <div className='col' onClick={() => { handleDeleteComment(comment.id, comment.userId, currentUserData.id) }}>
            <span>Delete comment</span><FontAwesomeIcon icon={faTrashCan} className="commentSettings"  />
            </div>
            </div>
          )}
      </div>
      </div>
    
    </div>
  ))}
</div>
          </div>

        </div>
      )}
    </div>
  </StyledDiv>

  )
}


const StyledDiv = styled.div`

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}
pre{
  font-family: Arial, Helvetica, sans-serif;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.comment-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;

}

.comment-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
}

.comment-input-container input {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.comment-input-container button {
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  background-color: #0084ff;
  color: white;
  border-radius: 5px;
}



.comment-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.commentAndName {
  display: flex;
  align-items: center;
  flex-grow: 1;
  border-radius:10px;
  background-color: rgb(245 245 245);
  padding-left:5px;
  padding-top:5px;
  margin-right:10px;
  justify-content:space-between;

}

.commentAndName p{
  margin:0;
  padding:0;
}

.comments-list {
  max-height: 500px; 
  min-height:200px;
  overflow-y: auto;
  overflow-x:hidden ;
}

.comment-item .dateAndSettings {
  position:relative ;
  display: flex;
  align-items: center;
}

.comments-list .time {
  margin-right: 10px;
}

.commentSettings {
  cursor:pointer ;
}

.comment-input-container .faPaperPlane {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.comment-input-container .faPaperPlaneActive {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: green;
}

.closeButton {
  cursor: pointer;
  margin: 4px;
}

.closeButton:hover {
  scale: 1.2;
}

.commentIcon:hover {
  color: #0d6efd;
  cursor: pointer;
}

.optionsMenu{
  position:absolute;
  right:50%;
  top:0;
  max-width:200px;
  min-width:190px;
  background-color:#e6e9ed;
  z-index:2000;
  border-radius:15px ;
  border:0.5px solid gray;

  
}
.optionsMenu span{
  margin-right:7px;
}

.col{
  padding:5px;
  margin:5px ;
}
.col:hover{
  cursor:pointer;
  background-color:green ;
  color:white;
  border-radius:15px;
}
.comment-input-container textarea {
  height: auto;
  min-height: 50px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
  overflow: hidden;

}
`
