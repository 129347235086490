import React, { useState, useEffect, useRef } from 'react';
import 'firebase/storage';
import { storage } from '../firebase/firebase';
import styled from 'styled-components';
import { useAuth } from '../context/context';
import imageCompression from 'browser-image-compression';
import AvatarEditor from 'react-avatar-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight, faRotateLeft } from '@fortawesome/free-solid-svg-icons'

export default function UploadBannerImage() {
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const {currentUserData, updateUserBannerImg, setBannerImageChanged, bannerImageChanged } = useAuth();
  const [prevImageUrl, setPrevImageUrl] = useState();

  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const editorRef = useRef();

  useEffect(() => {
    if (imageUrl && isUploaded && currentUserData && currentUserData.userBannerImg && currentUserData.userBannerImg !== imageUrl) {
      const regex = /images%2F(.+?)\?/; // a regular expression to match the image name in the URL
      const prevImageName = regex.exec(currentUserData.userBannerImg)[1]; // extract the image name from the URL using the regular expression
      const prevImageRef = storage.ref().child(`images/${prevImageName}`);
      prevImageRef.delete()
        .then(() => console.log("previous image deleted successfully"))
        .catch((error) => console.log(error));
    }
  }, [imageUrl, isUploaded, currentUserData]);

  const handleChange = async (e) => {
    if (e.target.files[0]) {
      try {
        const compressedImage = await imageCompression(e.target.files[0], {
          maxSizeMB: 0.5, // max size in MB
          maxWidthOrHeight: 720, // max width or height of the image
        });
        setImage(compressedImage);
        setImageUrl(URL.createObjectURL(compressedImage));
        setPrevImageUrl(imageUrl);
        setProgress(0);
        setIsUploaded(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUpload = () => {
    if (editorRef.current) {
        const canvas = editorRef.current.getImage();
        // Convert the canvas to a Blob (a format we can use to upload the file)
        canvas.toBlob(async (blob) => {
            const file = new File([blob], "filename");
            // Upload file to Firebase
            const imageName = `${new Date().getTime()}_${file.name}`; // a unique image name
            const uploadTask = storage.ref(`images/${imageName}`).put(file);
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                },
                (error) => {
                    console.log(error);
                },
                () => {
                    storage
                        .ref('images')
                        .child(imageName)
                        .getDownloadURL()
                        .then((url) => {
                            setImageUrl(url);
                            setIsUploaded(true);
                            updateUserBannerImg(url);
                            setBannerImageChanged(!bannerImageChanged);
                        });
                }
            );
        }, "image/jpeg");
    }
};


  const rotateLeft = () => {
    setRotation(rotation - 90);
  };

  const rotateRight = () => {
    setRotation(rotation + 90);
  };

  const handleScale = (e) => {
    setScale(parseFloat(e.target.value));
  };

  return (
    <StyledDiv>
      {imageUrl && !isUploaded ? (
        <>
        <AvatarEditor
          ref={editorRef}
          image={imageUrl}
          width={350}
          height={200}
          border={50}
          rotate={rotation}
          scale={scale}
        />
        <div>
          <FontAwesomeIcon icon={faRotateLeft} onClick={rotateLeft} className="mx-2"/>

          <FontAwesomeIcon icon={faRotateRight} onClick={rotateRight}/>
        </div>
        
    
        <input name="scale" type="range" onChange={handleScale} min="1" max="3" step="0.01" defaultValue="1" />
        <UploadButton onClick={handleUpload}>Upload</UploadButton>
        </>
      ) : (
        <InputContainer>
          <input type="file" onChange={handleChange} />
          {!isUploaded && (
            <UploadButton onClick={handleUpload}>Upload</UploadButton>
          )}
        </InputContainer>
      )}
      {progress < 100 && <ProgressBar value={progress} max="100" hidden={!image} />}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PreviewImage = styled.img`
  max-width: 300px;
  max-height: 300px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UploadButton = styled.button`
  margin-top: 16px;
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3e8e41;
  }`;

const ProgressBar = styled.progress`
  margin-top: 16px;
`;