import React, {useRef, useState} from 'react'
import { Card, Form, Button, Alert, Modal   } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from '../context/context'
import { doc, updateDoc } from "firebase/firestore"; 

import { db } from "../firebase/firebase";

import styled from 'styled-components'

import UploadImage from '../components/UploadImage';
import UploadBannerImage from '../components/UploadBannerImage';


export default function UpdateProfile() {

    const emailRef = useRef()
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const userNameRef = useRef()
    const { currentUser, updatePassword, currentUserData,useEffectToggle, setUseEffectToggle } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);
    const [showModalBanner, setShowModalBanner] = useState(false)
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);
    const handleModalBannerShow = () => setShowModalBanner(true);
    const handleModalBannerClose = () => setShowModalBanner(false);
    

     function handleSubmit(e){
        e.preventDefault()
        if(passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match")
        }

        const promises = []
        setLoading(true)
        setError("")
     
        if(passwordRef.current.value){
            promises.push(updatePassword(passwordRef.current.value))
        }
        if(userNameRef.current.value.length > 0){
            promises.push(updatePersonal(userNameRef.current.value))
        }

       
        Promise.all(promises).then(()=>{
            navigate('/')
        }).catch(()=>{
            setError('Failed to update account!')
        }).finally(()=>{
            setLoading(false)

        })
    }

    async function updatePersonal(userName){
        await updateDoc(doc(db, "users", currentUser.email),{
            userName: userName
        }).then(setUseEffectToggle(!useEffectToggle)
)
    }




  return (
    <StyledDiv>
    <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
    <div className="w-100" style={{maxWidth: "400px"}}> 
    <Card>
        <Card.Body >
            <h2 className='text-center mb-4'>Update Profile</h2>
            {error && <Alert variant='danger'>{error}</Alert>}
            <div className="text-center">
            {currentUserData && currentUserData.userImg ? <PreviewImage  src={currentUserData.userImg} alt="preview" /> : <span className='addImgText' onClick={handleModalShow}>add profile image</span>}
            </div>
            

            <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required readOnly={currentUser.email} defaultValue={currentUser.email}/>
                </Form.Group>
                <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef}  placeholder='Leave blank to keep the same password' />
                </Form.Group>
                <Form.Group id="passwordConfirm">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" ref={passwordConfirmRef}  placeholder='Leave blank to keep the same password'/>
                </Form.Group>

                <Form.Group id="userName">
                <Form.Label>User Name</Form.Label>
                <Form.Control type="text" ref={userNameRef} placeholder={currentUserData && currentUserData.userName} />
                </Form.Group>

                <Button variant="primary" className='w-100 text-center my-1' onClick={handleModalShow}>
                        {currentUserData && currentUserData.userImg ? <span>Change image</span> : <span>Upload image</span>}   
                    </Button>

                <Modal show={showModal} onHide={handleModalClose} centered>
                    <Modal.Header closeButton>
                    <Modal.Title>Change profile picture</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <UploadImage  />
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>


                <Button variant="primary" className='w-100 text-center my-1' onClick={handleModalBannerShow}>
                        {currentUserData && currentUserData.userImg ? <span>Change banner image</span> : <span>Upload banner image</span>}   
                    </Button>

                    <Modal show={showModalBanner} onHide={handleModalBannerClose} centered>
                    <Modal.Header closeButton>
                    <Modal.Title>Change banner picture</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <UploadBannerImage  />
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
 


                <Button disabled={loading} type="submit" className='w-100 text-center btn btn-success my-1'>Update</Button>
                <div className='w-100 text-center mt-2'>

                <Link to="/">Cancel</Link>
                </div>




    
            </Form>
        </Card.Body>
    </Card>
   
        </div>
        </div>


        
  
        </StyledDiv>
  )
}

const PreviewImage = styled.img`
  max-width: 300px;
  max-height: 300px;
`;

const StyledDiv = styled.div`

p {
   width: 100%; 
   text-align: center; 
   border-bottom: 1px solid darkgray; 
   line-height: 0.1em;
   margin: 10px 0 20px; 
} 

p span { 
    background:#fff; 
    padding:0 10px; 
}


.addImgText{
    cursor: pointer;
}

`
