import React,{useState, useEffect} from 'react'
import {Card, Button, Alert} from "react-bootstrap"
import { useAuth } from '../context/context'
import { db } from "../firebase/firebase";
import { doc, collection, query, where, getDocs,deleteDoc, orderBy } from "firebase/firestore";
import VideoPlayer from "../components/VideoPlayer";
import PhotoPlayer from '../components/PhotoPlayer';
import { Link, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEarthAmericas,faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import CommentBox from '../components/CommentBox';




export default function UserSettings() {
    const navigate = useNavigate()

    const [error, setError] = useState("")
    const {currentUser, logout, currentUserData } = useAuth()

    const [userDocument, setUserDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [stateUpdate, setStateUpdate] = useState(false)




    useEffect(() => {
      const fetchPosts = async () => {
        const postsRef = collection(db, "posts");
        const q = query(postsRef, 
          where("postCreatedBy", "==", currentUser.email),
          orderBy("timestamp", "desc")
        );
        const querySnapshot = await getDocs(q);
        const postsData = querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        setPosts(postsData);
      };
  
      fetchPosts();
      setLoading(false)
    }, [currentUser.email, stateUpdate ]);


   async function handleLogout(){
        setError('')
        try {
            await logout()
            navigate("/")
        } catch {
            setError('Logout failed')
        }
    }

    const handleDeletePost = async (postId) => {
      try {
        if (!currentUser) return;
    
        // Get reference to comments subcollection
        const commentsRef = collection(db, "posts", postId, "comments");
    
        // Get all comments
        const commentsSnapshot = await getDocs(commentsRef);
    
        // Delete all comments
        commentsSnapshot.forEach((commentDoc) => {
          deleteDoc(commentDoc.ref);
        });
    
        // Delete the post
        const docRef = doc(db, "posts", postId);
        await deleteDoc(docRef);
        console.log(`Document with ID ${postId} successfully deleted!`);
        setStateUpdate(!stateUpdate);
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    };
 

 

  return (
<StyledDiv>
    <div className='container'>
        <div className='row mt-5'>
          <div className='col-12 col-sm-4'>

                      <div className="w-100" style={{maxWidth: "400px"}}> 

        <Card>
            <Card.Body className='text-center'>

           {currentUserData && currentUserData.userName ? (<Link to={`/user/${currentUser.email}`}><h2>{currentUserData.userName}</h2></Link>) : (<span></span>)}
            {error && <Alert variant='danger'>{error}</Alert>}

            <p><strong>Email: </strong>{currentUser.email}</p>

           { currentUserData && currentUserData.userImg ?  (<PreviewImage src={currentUserData.userImg} alt="preview" />) :  ( <span></span>)  }

            <Link to="/updateprofile" className="btn btn-primary w-100 mt-3">Update Profile</Link>

            </Card.Body>
        </Card>
        <div className='w-100 text-center mt-2'>
        <Button variant="link"  onClick={handleLogout}>Log Out</Button>
        </div>
    </div>

          </div>
          <div className='col-12 col-sm-8'>

          {posts && !loading ? (
        <div>
          <h2>Your posts</h2>
          {posts.map((post) => (
            <div key={post.id}>
            <div className='userWrapper'>
            <div className='userProfileWrapper'>
              <img className="profileImg" src={post.postCreatedByUserImg} alt="userImg"/>
              <div>
                <Link to={`/user/${post.postCreatedBy}`}><div className='userName'>{post.postCreatedByName}</div></Link>
                <div className="postDescription">
                  {post && post.description ? (post.description):(<span></span>)} 
                </div>
                <div className="tagTime">
                  <div className='postTags'>{
                    post.tags.map((tag, index)=>{
                      return (<span key={index} className="mx-1">{"#"+tag}</span>)
                    })
                  }</div>
                  <div className="time">{new Date(post.timestamp.seconds * 1000 + post.timestamp.nanoseconds / 1000000).toLocaleString()}</div>
                </div>
              </div>
            </div>
          </div>
              <h3>{post.postTitle}</h3>
                  {post.videoFileName.split(".").pop().toLowerCase() !== "mp4" && post.videoFileName.split(".").pop().toLowerCase() !== "mov" ? 
(<PhotoPlayer src={post.videoUrl} />)
:
(<VideoPlayer src={post.videoUrl} />
)}
              

             <div className='likeDislikeWrapper'>
             <div className='delete mx-3'> 

                          <FontAwesomeIcon className='deleteIcon' onClick={()=>{handleDeletePost(post.id)}}  icon={faTrashCan} color="red"  size="xl"  />
                          </div>      

                          <div className='likeDislikeWrapper'>
              <div className='thumbsUp mx-3'> 
                         
                <FontAwesomeIcon   
                icon={faThumbsUp}  
                size="xl"  
                className='likeDislike'
                style={{ color: (currentUserData && currentUserData.userEmail && post.likedBy.includes(currentUserData.userEmail)) ? '#0d6efd' : 'gray' }} 

                />
                      <span className='mx-2'>{post.likes}</span>
            </div>      
            <div className='thumbsDown mx-3'>         
                <FontAwesomeIcon 
                icon={faThumbsDown} 
                size="xl" 
                className='likeDislike'
                style={{ color: (currentUserData && currentUserData.userEmail && post.dislikedBy.includes(currentUserData.userEmail)) ? '#0d6efd' : 'gray'  }}
                />
                      <span className='mx-2'> {post.dislikes}</span>

            </div>
            <CommentBox postId={post.id}/>
                    <span className='mx-2'>{post.commentCount}</span>
            
      </div>
         
      </div> 


                </div>
          ))}
        </div>
      ) : (
        <span>Loading...</span>
      )}

          </div>
        </div>
      </div>




</StyledDiv>
  )
}


const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 300px;
  margin:auto ;

  `;
  const StyledDiv = styled.div`
  margin-bottom:20px;

.likeDislikeWrapper{
  display:flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.deleteIcon{
    cursor:pointer ;

}

.userWrapper {
    display: flex;
    align-items: center;
    padding: 12px;
  }

  .userProfileWrapper {
    display: flex;
    align-items: center;
    margin-right: 12px;

    .profileImg {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 12px;
    }

    .userName {
      font-weight: bold;
      margin-bottom: 4px;
    }
  }

  .postTags {
    color: #5c5c5c;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .time{
    color: #5c5c5c;
    font-size: 12px;
  }

  .postTags span {
    margin-right: 4px;
  }

  .postDescription{
  overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
  }
  
  `

  
