import React, { useState, useRef, useEffect } from 'react'
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faVolumeLow, faExpand, faUndo, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../context/context';
import { usePlayingVideo } from '../context/PlayingVideoContext';




export default function VideoPlayer(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
 // const [volume, setVolume] = useState(0.5);
  const videoRef = useRef(null);
  const { bigVideo, handleBigVideo } = useAuth();
  const [hasEnded, setHasEnded] = useState(false);
//  const [isMuted, setIsMuted] = useState(false);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const [showVolumeBar, setShowVolumeBar] = useState(false);




  const {setPlayingVideo, volume, saveVolume, toggleMute, isMuted} = usePlayingVideo();



  useEffect(() => {
    if ('IntersectionObserver' in window) { // Check if IntersectionObserver is supported
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
if (videoRef.current) {
  if (entry.isIntersecting) {
    setPlayingVideo(videoRef.current);
    videoRef.current.play().then(() => {
      setIsPlaying(true);
    }).catch(error => {
      // Handle error if any
    });
  } else {
    videoRef.current.pause();
    setIsPlaying(false);
  }
}


        },
        {
          threshold: 0.5  // Adjust this value if needed (0.5 means 50% of the video should be in view)
        }
      );
  
      if (videoRef.current) {
        observer.observe(videoRef.current);
      }
      
      if (videoRef.current) {
        videoRef.current.volume = volume; // Initialize volume from global state
        videoRef.current.muted = isMuted; // Synchronize video's muted state with global state
      }
  
      return () => {
        // Cleanup
        if (videoRef.current) {
          observer.unobserve(videoRef.current);
        }
      };
    }
  }, [videoRef, volume, isMuted]);
  

  useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);




const handlePlayPause = () => {
  setIsPlaying(!isPlaying);
  setHasEnded(false);
  if (!isPlaying) {
    setPlayingVideo(videoRef.current);
    videoRef.current.play().then(() => {
      setIsPlaying(true);
    }).catch(error => {
      // Handle error if any, e.g. due to auto-play policies
    });
  } else {
    videoRef.current.pause();
  }
};



 // const handleVolumeChange = (e) => {
 //   setVolume(e.target.value);
 //   videoRef.current.volume = e.target.value;
 // };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    const progressPercentage = (currentTime / duration) * 100;
    setProgress(progressPercentage);
  };

  const handleProgressChange = (e) => {
    const progressPercentage = e.target.value;
    const duration = videoRef.current.duration;
    const currentTime = (progressPercentage / 100) * duration;
    setProgress(progressPercentage);
    videoRef.current.currentTime = currentTime;
  };

  const formatTime = (timeInSeconds) => {
    // Safety check for NaN, negative, or extremely large values
    if (!Number.isFinite(timeInSeconds) || timeInSeconds < 0 || timeInSeconds > 8.64e7) {
        console.warn("Invalid timeInSeconds value provided:", timeInSeconds);
        return { minutes: '00', seconds: '00' };
    }

    // Calculate minutes and seconds
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    // Convert to strings and ensure they have two digits
    return {
        minutes: String(minutes).padStart(2, '0'),
        seconds: String(seconds).padStart(2, '0'),
    };
};

  


//  const toggleMute = () => {
//    setIsMuted(!isMuted);
//    videoRef.current.muted = !isMuted;
//};

const toggleVolumeBar = () => {
  setShowVolumeBar(!showVolumeBar);
};



  return (
<StyledDiv>
<div className='videoWrapper'>
    <div className={bigVideo ? ("bigVideo") : ("smallVideo")}> 
    {hasEnded && (
    <ReplayIcon onClick={handlePlayPause}>
      <FontAwesomeIcon icon={faUndo} />
    </ReplayIcon>
  )}
            <video
        ref={videoRef}
        src={props.src}
        onTimeUpdate={handleTimeUpdate}
        onEnded={() => {
        setIsPlaying(false);
        setHasEnded(true);
        }}

        onClick={handlePlayPause}
      />
      <Controls>
        <PlayPauseButton onClick={handlePlayPause}>
          {isPlaying ? (
            <FontAwesomeIcon icon={faPause} />
          ) : (
            <FontAwesomeIcon icon={faPlay} />
          )}
        </PlayPauseButton>
        <ProgressBar
          type="range"
          min="0"
          max="100"
          step="0.01"
          value={progress}
          onChange={handleProgressChange}
        />
        <TimeWrapper>
        <Time>{formatTime(progress * (videoRef.current?.duration || 0) * 0.01).minutes}:{formatTime(progress * (videoRef.current?.duration || 0) * 0.01).seconds}</Time>
        <Time>{formatTime(videoRef.current?.duration || 0).minutes}:{formatTime(videoRef.current?.duration || 0).seconds}</Time>

        </TimeWrapper>
        <VolumeWrapper onClick={windowWidth <= 768 ? toggleMute : null}>
    {windowWidth > 768 ? (
        <FontAwesomeIcon 
            icon={faVolumeLow} 
            color="white" 
            onClick={toggleVolumeBar} 
            className='volumeIcon'
        />
    ) : (
        <FontAwesomeIcon 
            icon={isMuted ? faVolumeMute : faVolumeUp} 
            color="white"
            className='volumeIcon'
            onClick={toggleMute} // Toggle mute state when icon is clicked


        />
    )}
    {showVolumeBar && (
      <VolumeBar
      type="range"
      min="0"
      max="1"
      step="0.01"
      value={volume}
      onChange={saveVolume} // Here we pass the event directly to the saveVolume function
  />
    )}
</VolumeWrapper>

      {/*  {bigVideo ? 
        (<FontAwesomeIcon icon={faCompress} color="white" className="expand" onClick={handleBigVideo}/>)
        :
        (<FontAwesomeIcon icon={faExpand} color="white" className="expand" onClick={handleBigVideo}/>)}*/}
      </Controls>
    </div>
    </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`

.videoWrapper {
    position: relative;
    width: 100%;
    max-width: 680px;
    height: 500px;
    margin-bottom: 15px;
  }

  .smallVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    margin-bottom: 12px;
    border-top-left-radius: 10px;  // Adjust this to your preference
    border-top-right-radius: 10px;  // Adjust this to your preference
  }

`;



const Controls = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 26px;  // Adjust this to your preference
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius:25px;
 


  .expand{
    display:flex ;
    margin-right:10px;
    cursor:pointer ;
  }
`;

const PlayPauseButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const ProgressBar = styled.input`
  flex: 1;
  margin: 0 10px;
  cursor: pointer;
  @media(max-width:768px){
    width:80% ;
  }
  &:focus {
    outline: none;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
`;

const Time = styled.span`
  font-size: 14px;
  margin: 0 5px;
`;

const VolumeWrapper = styled.div`
  display: flex;
  align-items: center;
  
  .volumeIcon{
    margin-right:10px ;
  }
`;


const VolumeBar = styled.input`
  margin: 0 10px;
  cursor: pointer;
  @media(max-width:768px){
    display:none;
  }
  &:focus {
    outline: none;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
  

`;


const ReplayIcon = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-color: transparent;
  font-size: 36px; // Adjust as needed
  color: white;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
