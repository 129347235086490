import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Link} from "react-router-dom"
import { useAuth } from '../context/context'
import logo from "../media/images/PostemicLogo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Notifications from './Notifications'


export default function Nav() {
    const {currentUser, currentUserData} = useAuth()
    const [user, setUser] = useState()
    const [loading, setLoading] =useState(false)
    const [mobileMenuOpen, setMobileMenuOpen] =useState(false)

    const handleHamburger=()=>{
      setMobileMenuOpen(!mobileMenuOpen)
    }


    useEffect(()=>{
        setLoading(true)
        if(currentUser){
            const email = currentUser.email;
            const name = email.slice(0,email.indexOf('@'))
            setUser(name)
            setLoading(false)
        }

    },[currentUser])
  return (
    <>
    <StyledDiv>
    <div className='container'>
    
        <div className='logoImg'><a href="/"><img src={logo} className="logo" alt="logo"/></a></div>
        <div className='topWrapper'>
          <div className='search'>
                <input placeholder='Search'></input>
            </div>
            { (<Link to="/upload" className='uploadLink'><div className='upload'>Upload</div></Link>)  }

              <Notifications/>
            <div className='profileWrapper'>
  {!loading && currentUser ?  
    ( currentUserData ? 
      (<Link to="/usersettings" className='userSettingsLink'>
        <span>{currentUserData.userName || currentUser.email}</span> 
        {currentUserData && currentUserData.userImg ? 
          <img className='navbarUserImg' src={currentUserData.userImg} alt="userImg" /> 
          : <span></span>} 
      </Link>) 
      : 
      (<Link to="/usersettings" className='userSettingsLink'>
        <span>{user || currentUser.email}</span> 
        {currentUserData && currentUserData.userImg ? 
          <img className='navbarUserImg' src={currentUserData.userImg}></img> 
          : <span></span>} 
      </Link>)  
    )
    :  
    (<Link to="/login" className='userSettingsLink'>Login</Link>)  
  } 
</div>
        </div>

            {mobileMenuOpen && <div className='mobileWrapper'>
          <div className='search'>
                <input placeholder='Search'></input>
            </div>
            { <Link to="/upload" className='userSettingsLink' onClick={()=>{setMobileMenuOpen(false)}}><div className='uploadMobile'>Upload</div></Link> }
            <div className='profileWrapper'>
  {!loading && currentUser ?  
    ( currentUserData ? 
      (<Link to="/usersettings" className='userSettingsLink' onClick={()=>{setMobileMenuOpen(false)}}>
        <span>{currentUserData.userName || currentUser.email}</span> 
        {currentUserData && currentUserData.userImg ? 
          <img className='navbarUserImg' src={currentUserData.userImg} alt="userImg"/> 
          : <span></span>} 
      </Link>) 
      : 
      (<Link to="/usersettings" className='userSettingsLink' onClick={()=>{setMobileMenuOpen(false)}}>
        <span>{user || currentUser.email}</span> 
        {currentUserData && currentUserData.userImg ? 
          <img className='navbarUserImg' src={currentUserData.userImg} alt="userImg" />
          : <span></span>} 
      </Link>)  
    )
    :  
    (<Link to="/login" className='userSettingsLink' onClick={()=>{setMobileMenuOpen(false)}}>Login</Link>)  
  } 
</div>
            {currentUserData ? 
    (<>
      <Link to="/following" className='link' onClick={()=>{setMobileMenuOpen(false)}}> <div className='someContent my-2'>Following</div>  </Link>
        <Link to="/followers" className='link' onClick={()=>{setMobileMenuOpen(false)}}><div className='someContent my-2'>Followers</div>   </Link>
        </>)
    :
    (<span></span>)
    }

    <Link to="/explore" className='link' onClick={()=>{setMobileMenuOpen(false)}}><div className='someContent'>Explore</div></Link>
    <Link to="/about" className='link' onClick={()=>{setMobileMenuOpen(false)}}><div className='someContent'>About</div></Link>
    
          <Notifications />
        </div>
           }
            <div className='hamburgerIconWrapper'>
              <FontAwesomeIcon icon={faBars} className="hamburgerIcon" onClick={()=>{handleHamburger()}}/>
           </div>
           
       
    </div>

    

    </StyledDiv>
    </>

  )
}

const StyledDiv = styled.div`
 background-color: #333;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  background: rgba(255, 255, 255, 1);
  margin: 0 auto;
  padding: 10px;
  
  .mobileWrapper{
   position:absolute;
   top:35px;
   right:35px;
  z-index:2000;
  border-radius:15px ;
  border:0.5px solid gray;
  padding:10px ;
  background-color:white;
  white-space:nowrap;
  overflow:hidden ;
  }
  .topWrapper{
    display:flex;
    justify-content:space-around;
    flex-grow:1;
  }

  @media(max-width:769px){
    .topWrapper{
      display:none;
    }
  }
  
  @media(min-width:769px){
     .hamburgerIcon{
      display:none
     }
  }
  .hamburgerIcon{
    cursor:pointer;
  }
  .hamburgerIconWrapper{
    display:flex;
    margin:10px;
    
  }
 
  .logo{
    max-height:35px;
  }
  .container {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
.profileWrapper{
    display: inline-flex;
    flex-direction:row ;
    padding-left:-5px;
}
.profileWrapper:hover{
  color: black;
    background-color:rgb(221 221 221 / 35%) ;
    border-radius: 5px;

}
  .logoImg {
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
  }

  .search input {
    padding: 5px;
    background-color:rgb(221 221 221 / 35%) ;
    border-radius: 50px;
    border: 1px solid transparent;
    margin-left: 10px;
    -webkit-appearance: none;

    &:hover,&:focus-visible{
        border:1px solid gray;
    }
  }

  .textarea, input {
    outline: none;
  }

  .upload,
  .userProfile,
  .logIn
 {
    color: #333;
    padding: 5px 10px;
    border:1px solid gray;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  .uploadLink{
    text-decoration:none;
  }
  .uploadMobile{
    color: #333;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
  }
  .uploadMobile:hover{
    color: black;
    background-color:rgb(221 221 221 / 35%) ;
  }

  .userSettingsLink{
    padding: 5px;
    text-decoration:none;
    color: #333;
    cursor: pointer;
    border-radius: 5px;

  }

 

  .upload:hover,
  .userProfile:hover,
  .logIn:hover
   {
    background-color:rgb(221 221 221 / 35%) ;
  }

  @media (max-width: 768px) {
    .container {
      align-items: flex-start;
    }

    .search input {
      margin-left: 0;
      margin-top: 10px;
      width: 100%;
    }

    .upload,
    .userProfile,
    .logIn
    {
      margin-left: 0;
      margin-top: 10px;
    }
  }

.navbarUserImg{
    height: 35px;
    width: 35px;
    border-radius:50%;
    margin-left:10px;
    margin-right:10px;
}

@media(min-width:769px){
  .hamburger{
  display:none;
}
}

.someContent{
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.someContent:hover{
  color: black;
    background-color:rgb(221 221 221 / 35%) ;
}

.link{
    text-decoration:none;
  }
`