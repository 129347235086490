import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import VideoPlayer from './VideoPlayer';
import { useAuth } from '../context/context';
import { db } from '../firebase/firebase';
import {Link   } from "react-router-dom"
import { doc, getDoc, collection, query, where, getDocs, updateDoc, limit, orderBy, startAfter } from 'firebase/firestore';
import { MapContainer, TileLayer, Marker, Popup, Rectangle } from 'react-leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp, faComment,faEarthAmericas, faShare, faLink } from '@fortawesome/free-solid-svg-icons'
import Geohash from 'latlon-geohash';
import PhotoPlayer from './PhotoPlayer';
import CommentBox from './CommentBox';



const PAGE_SIZE = 2;


export default function Explore() {
  const [posts, setPosts] = useState([]);
  const { currentUserData } = useAuth();
  const [userLocation, setUserLocation] = useState(null);
  const [stateUpdate, setStateUpdate] = useState(false)
  const [showMapMobile, setShowMapMobile] = useState(false)
  const [guestLocation, setGuestLocation] = useState()
  const [lastVisible, setLastVisible] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [mapVisible, setMapVisible] = useState(window.innerWidth > 768);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [openSharePostId, setOpenSharePostId] = useState(false);
  const [copiedPostId, setCopiedPostId] = useState(null);

  const shareContentRef = useRef(null);


  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
  
    window.addEventListener('resize', handleResize);
  
    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (openSharePostId && shareContentRef.current && !shareContentRef.current.contains(e.target)) {
        setOpenSharePostId(null);
      }
    };
  
    document.addEventListener('click', handleOutsideClick);
  
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openSharePostId]);

  useEffect(() => {
    const fetchPosts = async () => {
        try {
            let posts = [];
            // Query to get latest 10 posts
            const postsQuery = query(collection(db, 'posts'), orderBy('timestamp', 'desc'), limit(10));
            const postsSnapshot = await getDocs(postsQuery);
            
            postsSnapshot.forEach((doc) => {
                const postData = doc.data();
                postData.id = doc.id; // Add the document ID to the post data
                posts.push(postData);
            });
            setPosts(posts);
        } catch (error) {
            console.error("Error fetching posts: ", error);
        }
    };
    fetchPosts();
}, []); 



  useEffect(() => {
    const getLocation = async () => {
      if (!currentUserData) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setGuestLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }
        );
      } else {
        setUserLocation([currentUserData.lat, currentUserData.lng]);
      }
    };
    getLocation();
  }, [currentUserData]);


//const handleShowMapMobile=()=>{
//  setShowMapMobile(!showMapMobile)
//}

const handleShowMapMobile = (postId) => {
  setSelectedPost(prevPost => prevPost === postId ? null : postId);
  setMapVisible(true);

};

///////////////////////////////////////////////////////////  Helper functions start
  //////////////////////// take one geohash + neighbors and generate a new geohash
  function mergeGeohashesAndNeighbors(centerGeohashes) {
    const allGeohashes = new Set(centerGeohashes);
    const tempNeighbors = [];
  
    for (const centerGeohash of centerGeohashes) {
      const centerAndNeighbors = Geohash.neighbours(centerGeohash);
  
      for (const neighborGeohash of Object.values(centerAndNeighbors)) {
        if (!allGeohashes.has(neighborGeohash)) {
          tempNeighbors.push(neighborGeohash);
          allGeohashes.add(neighborGeohash);
        }
      }
    }
  
    return [...allGeohashes];
  }
  

  //////////////////// Get "the box" around geohash
  function getBoundingBoxFromGeohashes(geohashes) {
    if (!Array.isArray(geohashes)) {
      throw new Error('geohashes must be an array');
    }
  
    const bbox = geohashes.reduce((acc, geohash) => {
      const { lat, lon } = Geohash.decode(geohash);
      return {
        minLat: Math.min(acc.minLat, lat),
        maxLat: Math.max(acc.maxLat, lat),
        minLng: Math.min(acc.minLng, lon),
        maxLng: Math.max(acc.maxLng, lon),
      };
    }, {
      minLat: 90,
      maxLat: -90,
      minLng: 180,
      maxLng: -180,
    });
  
    const { minLat, maxLat, minLng, maxLng } = bbox;
  
    const cbox = [minLat, minLng, maxLat, maxLng];
    console.log(cbox)
    return cbox.flat();
  }


//function estimateGeohashRadius(hash) {
//  const charCount = hash.length;
//  let radiusMeters;
//
//  switch (charCount) {
//    case 1:
//      radiusMeters = 500000;
//      break;
//    case 2:
//      radiusMeters = 125000;
//      break;
//    case 3:
//      radiusMeters = 35000;
//      break;
//    case 4:
//      radiusMeters = 1950 / Math.pow(2, charCount - 6);
//      break;
//    case 5:
//      radiusMeters = 1460 / Math.pow(2, charCount - 6);
//      break;
//    case 6:
//      radiusMeters = 19500 / Math.pow(2, charCount - 6);
//      break;
//    case 7:
//      radiusMeters = 2440 / Math.pow(2, charCount - 8);
//      break;
//    default:
//      radiusMeters = 610 / Math.pow(2, charCount - 10);
//  }
//
//  return radiusMeters;
//}
//
/////////////////////////////////////////////////////helper functions end

const handleLike = async (postId) => {
  setIsLoading(true);
  if (!currentUserData) return;
  const postRef = doc(db, 'posts', postId);
  const postSnapshot = await getDoc(postRef);
  if (!postSnapshot.exists()) {
    console.log('No matching documents.');
    setIsLoading(false);
    return;
  }
  const post = postSnapshot.data();
  
  // Check if the user has already liked the post
  const userEmail = currentUserData.userEmail;
  const likedIndex = post.likedBy.indexOf(userEmail);
  const dislikedIndex = post.dislikedBy.indexOf(userEmail);
  
  if (likedIndex >= 0) {
    // User has already liked the post, do nothing
    console.log('User has already liked the post.');
    setIsLoading(false);
    return;
  } else {
    const updateData = {};
    if (dislikedIndex >= 0) {
      // User has disliked the post, remove from dislikedBy array and add to likedBy array
      const updatedDislikedBy = post.dislikedBy.slice();
      updatedDislikedBy.splice(dislikedIndex, 1);
      updateData.dislikedBy = updatedDislikedBy;
      updateData.dislikes = post.dislikes - 1;
    }
    // Add user to likedBy array and increment likes
    updateData.likedBy = [...post.likedBy, userEmail];
    updateData.likes = post.likes + 1;

    // Calculate the current spread power based on the number of likes and dislikes
    const spreadPower = updateData.likes - (updateData.dislikes || post.dislikes);

    // Increment the spread power
    updateData.spreadPower = spreadPower + 1;

    if (post.geohash.length >= 1 && spreadPower >= post.minSpreadPower[0]) {
      const tempNewGeohash = post.newGeohash;
      console.log("minSpreadPower before splice: " + post.minSpreadPower);
      updateData.spreadPower = 0;
      const newSpreadPower = post.minSpreadPower.slice(1);
      updateData.minSpreadPower = newSpreadPower;
      console.log("minSpreadPower after splice: " + post.minSpreadPower);
      const newNeighbours = mergeGeohashesAndNeighbors(tempNewGeohash);

      const [minLat, minLng, maxLat, maxLng] = getBoundingBoxFromGeohashes(newNeighbours);
      updateData.square = [minLat, minLng, maxLat, maxLng];
      updateData.zoom = post.zoom - 1;
      updateData.newGeohash = newNeighbours;
      console.log(newNeighbours);
    }

    // Update the post in the database
    await updateDoc(postRef, updateData);

    // Fetch updated post
    const updatedPostSnapshot = await getDoc(postRef);
    const updatedPost = { id: updatedPostSnapshot.id, ...updatedPostSnapshot.data() };

    // Replace the old post with the updated post in the 'posts' array
    setPosts(prevPosts => prevPosts.map(post => post.id === postId ? updatedPost : post));

    setIsLoading(false);
  }
};





const handleDislike = async (postId) => {
  setIsLoading(true);
  if (!currentUserData) return;
  const postRef = doc(db, 'posts', postId);
  const postSnapshot = await getDoc(postRef);
  if (!postSnapshot.exists()) {
    console.log('No matching documents.');
    setIsLoading(false);
    return;
  }
  const post = postSnapshot.data();
  
  // Check if the user has already liked or disliked the post
  const userEmail = currentUserData.userEmail;
  const likedIndex = post.likedBy.indexOf(userEmail);
  const dislikedIndex = post.dislikedBy.indexOf(userEmail);
  
  if (likedIndex >= 0) {
    // User has already liked the post, remove from likedBy array and add to dislikedBy array
    const updatedLikedBy = post.likedBy.slice();
    updatedLikedBy.splice(likedIndex, 1);
    const updatedDislikedBy = [...post.dislikedBy, userEmail];
    
    // Update the post data
    const updateData = {
      likes: post.likes - 1,
      dislikes: post.dislikes + 1,
      likedBy: updatedLikedBy,
      dislikedBy: updatedDislikedBy
    };
    await updateDoc(postRef, updateData);
  } else if (dislikedIndex >= 0) {
    // User has already disliked the post, do nothing
    console.log('User has already disliked the post.');
    setIsLoading(false);
    return;
  } else {
    // User has not liked or disliked the post, add to dislikedBy array
    const updateData = {
      dislikes: post.dislikes + 1,
      dislikedBy: [...post.dislikedBy, userEmail]
    };
    await updateDoc(postRef, updateData);
  }
  
  // Fetch updated post
  const updatedPostSnapshot = await getDoc(postRef);
  const updatedPost = { id: updatedPostSnapshot.id, ...updatedPostSnapshot.data() };

  // Replace the old post with the updated post in the 'posts' array
  setPosts(prevPosts => prevPosts.map(post => post.id === postId ? updatedPost : post));
  
  setIsLoading(false);
};
 

const handleShareOpen = (postId, e) => {
  e.stopPropagation(); // Stop the event from propagating further
  if (openSharePostId === postId) {
    setOpenSharePostId(null);
  } else {
    setOpenSharePostId(postId);
  }
}

 

const copyToClipboard = (text, postId) => {
  console.log(postId)

  navigator.clipboard.writeText(window.location.href + text).then(function() {
    setCopiedPostId(postId);
    setTimeout(() => {
      setCopiedPostId(null);
      setOpenSharePostId(null);


   }, 1000); // show "Copied!" message for 2 seconds

    console.log('Text successfully copied to clipboard');
  }).catch(function(err) {
    console.error('Failed to copy text: ', err);
  });
}

  return (
    <StyledDiv>
        
      {posts.map((post) => (
        <div className='feedItemWrapper' key={post.id}>
          <div className='userWrapper'>
            <div className='userProfileWrapper'>
              <img className="profileImg" src={post.postCreatedByUserImg} alt="profileImg"/>
              <div>
                <Link to={`/user/${post.postCreatedBy}`} className="link"><div className='userName'>{post.postCreatedByName}</div></Link>
                <div className="postDescription">
                  {post && post.description ? (post.description):(<span></span>)} 
                </div>
                <div className="tagTime">
                  <div className='postTags'>{
                    post.tags.map((tag, index)=>{
                      return (<span key={index} className="mx-1">{"#"+tag}</span>)
                    })
                  }</div>
                  <div className="time">{Math.ceil((post.timestamp.toMillis() + 600000000 - Date.now()) / (1000 * 60 * 60 * 24))} day(s) left</div>
                </div>
              </div>
            </div>
          </div>
<div className='mapAndVideoWrapper'>
{copiedPostId === post.id && <span className="copiedMessage">Copied!</span>}

<div className={selectedPost === post.id  ? 'videoPhotoWrapperFull' :'videoPhotoWrapperSmall'}>
{post.videoFileName.split(".").pop().toLowerCase() !== "mp4" && post.videoFileName.split(".").pop().toLowerCase() !== "mov" ? 
(<PhotoPlayer big={showMapMobile} src={post.videoUrl} />)
:
(<VideoPlayer src={post.videoUrl} />
)}
</div>


<div className={(windowWidth <= 1200 && selectedPost === post.id) ? 'showMapMobile' : 'mapWrapper mx-3'}>
  { 
    (windowWidth > 1200 || (windowWidth <= 1200 && selectedPost === post.id)) && mapVisible &&
    <MapContainer center={[post.lat, post.lng] || guestLocation} zoom={post.zoom} scrollWheelZoom={true}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Rectangle bounds={[[post.square[0], post.square[1]], [post.square[2], post.square[3]]]} />
      <Marker position={userLocation || guestLocation}>
        <Popup>
          <p>Your Location</p>
        </Popup>
      </Marker>
    </MapContainer>
  }
</div>


      </div>   
      <div className='likeDislikeWrapper'>
              <div className='thumbsUp mx-3'> 
                         
                <FontAwesomeIcon onClick={()=>{handleLike(post.id)}}  
                icon={faThumbsUp} 
                size="xl"  
                className='likeDislike'
                style={{ color: (currentUserData && currentUserData.userEmail && post.likedBy.includes(currentUserData.userEmail)) ? '#0d6efd' : 'gray' }} 

                />
                      <span className='mx-2'>{post.likes}</span>
            </div>      
            <div className='thumbsDown mx-3'>         
                <FontAwesomeIcon onClick={()=>{handleDislike(post.id)}} 
                icon={faThumbsDown} 
                size="xl" 
                className='likeDislike'
                style={{ color: (currentUserData && currentUserData.userEmail && post.dislikedBy.includes(currentUserData.userEmail)) ? '#0d6efd' : 'gray'  }}
                />
                      <span className='mx-2'> {post.dislikes}</span>
            </div>

              <CommentBox postId={post.id}/>
                  <span className='mx-2'>{post.commentCount}</span>
 
            <div className='mapIconSmallScreen mx-3'>
            <FontAwesomeIcon onClick={() => handleShowMapMobile(post.id)} icon={faEarthAmericas} className="globe" color="gray" size="xl"/>
            </div>

            <div className='shareWrapper'>
            <div className='shareIcon mx-3'>         
                <FontAwesomeIcon
                onClick={(e)=>{handleShareOpen(post.id , e)}}
                icon={faShare} 
                size="xl" 
                className=''
                />
            </div>

            {openSharePostId === post.id && 
  <div ref={shareContentRef} className='shareOpenContent'>
      <div onClick={()=>{copyToClipboard(`${post.id}`, post.id)}} className='copyTextWrapper'>
          <FontAwesomeIcon icon={faLink} size="xl" /> Copy link
      </div>
  </div>}

               

</div>
      </div>         
        </div>
        
      ))}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`

.postDescription{
  overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
  }

.copiedMessage {
  transition: ease-in-out ;
  position:absolute;
  bottom:-10px;
  left:50px;
   opacity: 1;
   transition: opacity 1.5s; /* adjust duration as needed */
   z-index:2000 ;
}

.shareOpenContent .copiedMessage {
   opacity: 0;
}


.copyTextWrapper{
  cursor: pointer;
}

.shareWrapper{
  position:relative;
}
.shareOpenContent{
  font-size:13px;
  font-weight:bold ;
   position:absolute;
   width:120px;
   top:-50px;
   right:60px;
  z-index:2000;
  border-radius:15px ;
  border:0.5px solid gray;
  padding:10px ;
  background-color:white;
  }

  .shareIcon{
  color:gray;
  cursor: pointer;
}
.shareIcon:hover{
  color:#0d6efd;;
}

.feedItemWrapper{
  margin-bottom:20px;
}

.likeDislikeWrapper{
  display:flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.map{
  height:50% ;
}

.noMorePosts{
  margin: auto;
  margin-top:35px;
  margin-bottom:25px;
}
.leaflet-container{
  height:100% ;
  width:100%;
}

.mapWrapper{
  width:100%;
  max-width:350px;
  height:350px;
  @media(max-width:1200px){
    display:none;
  }
}

.mapIconSmallScreen{
  @media(min-width:1200px){
    display:none;
  }
}

.showMapMobile{
  position:absolute;
  right:0px;
  bottom:28px;
  width:100%;
  height:90%;

}


.mapAndVideoWrapper{
  position:relative ;
  display: flex;
  width:100% ;
}

.videoPhotoWrapperSmall{
  width:100%;
  height:100%;
}

.videoPhotoWrapperFull{
  width:100%;
  height:100%;
}


.userWrapper {
    display: flex;
    align-items: center;
    padding: 12px;
  }

  .userProfileWrapper {
    display: flex;
    align-items: center;
    margin-right: 12px;

    .profileImg {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 12px;
    }

    .userName {
      font-weight: bold;
      margin-bottom: 4px;
      color:rgb(82 72 72);
    }
    .userName:hover{
      text-decoration:underline;
      text-decoration-thickness:1px;

    }
  }
  .link{
    text-decoration:none;
  }
  .postTags {
    color: #5c5c5c;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .time{
    color: #5c5c5c;
    font-size: 12px;
  }

  .postTags span {
    margin-right: 4px;
  }

  .thumbs{
    position: relative ;
  }

  .likeDislike{
    cursor: pointer;
  }

  .likeDislike:hover{
    color:#0d6efd ;
  }
  .globe:hover{
    color:#0d6efd;
    cursor:pointer;

  }

  .postPhoto{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    margin-bottom:12px;
  }


`;