import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import { doc, getDoc, collection, query, where, getDocs, updateDoc,  onSnapshot } from 'firebase/firestore';
import styled from 'styled-components';
import { Link } from "react-router-dom"
import { useAuth } from '../context/context';

import VideoPlayer from '../components/VideoPlayer';
import { MapContainer, TileLayer, Marker, Popup, Rectangle } from 'react-leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp, faComment,faEarthAmericas } from '@fortawesome/free-solid-svg-icons'
import PhotoPlayer from '../components/PhotoPlayer';
import CommentBox from '../components/CommentBox';
import Geohash from 'latlon-geohash';



export default function Following() {

    const [following, setFollowing] = useState([]);
    const [postFollowing, setPostFollowing] =useState([])
    const { currentUser,bigVideo, currentUserData } = useAuth();
    const userId = currentUser.email
    const [showMapMobile, setShowMapMobile] = useState(false)
    const [userLocation, setUserLocation] = useState(null);
    const [guestLocation, setGuestLocation] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [posts, setPosts] = useState([]);





    const handleShowMapMobile=()=>{
        setShowMapMobile(!showMapMobile)
      }


    useEffect(() => {
        const followingRef = collection(db, 'users', currentUser.email, 'following');
        const followingQuery = query(followingRef);
    
        const unsubscribe = onSnapshot(followingQuery, (snapshot) => {
          const newFollowing = snapshot.docs.map((doc) => doc.id);
          setFollowing(newFollowing);
        });
    
        return () => unsubscribe();
      }, [currentUser]);

      useEffect(() => {
        const fetchPosts = async () => {
            try {
                let posts = [];
    
                // Wait for all following users' posts to be fetched
                await Promise.all(following.map(async (following) => {
                    const postsQuery = query(collection(db, 'posts'), where('postCreatedBy', '==', following));
                    const postsSnapshot = await getDocs(postsQuery);
                    
                    postsSnapshot.forEach((doc) => {
                        const postData = doc.data();
                        postData.id = doc.id; // Add the document ID to the post data
                        posts.push(postData);
                    });
                }));
    
                setPostFollowing(posts);
            } catch (error) {
                console.error("Error fetching posts: ", error);
            }
        };
    
        if (following.length > 0) {
            fetchPosts();
        }
    }, [following]); // This useEffect runs whenever 'following' changes
    

    
    const handleLike = async (postId) => {
        setIsLoading(true);
        if (!currentUserData) return;
        const postRef = doc(db, 'posts', postId);
        const postSnapshot = await getDoc(postRef);
        if (!postSnapshot.exists()) {
          console.log('No matching documents.');
          setIsLoading(false);
          return;
        }
        const post = postSnapshot.data();
        
        // Check if the user has already liked the post
        const userEmail = currentUserData.userEmail;
        const likedIndex = post.likedBy.indexOf(userEmail);
        const dislikedIndex = post.dislikedBy.indexOf(userEmail);
        
        if (likedIndex >= 0) {
          // User has already liked the post, do nothing
          console.log('User has already liked the post.');
          setIsLoading(false);
          return;
        } else {
          const updateData = {};
          if (dislikedIndex >= 0) {
            // User has disliked the post, remove from dislikedBy array and add to likedBy array
            const updatedDislikedBy = post.dislikedBy.slice();
            updatedDislikedBy.splice(dislikedIndex, 1);
            updateData.dislikedBy = updatedDislikedBy;
            updateData.dislikes = post.dislikes - 1;
          }
          // Add user to likedBy array and increment likes
          updateData.likedBy = [...post.likedBy, userEmail];
          updateData.likes = post.likes + 1;
      
          // Calculate the current spread power based on the number of likes and dislikes
          const spreadPower = updateData.likes - (updateData.dislikes || post.dislikes);
      
          // Increment the spread power
          updateData.spreadPower = spreadPower + 1;
      
          if (post.geohash.length >= 1 && spreadPower >= post.minSpreadPower[0]) {
            const tempNewGeohash = post.newGeohash;
            console.log("minSpreadPower before splice: " + post.minSpreadPower);
            updateData.spreadPower = 0;
            const newSpreadPower = post.minSpreadPower.slice(1);
            updateData.minSpreadPower = newSpreadPower;
            console.log("minSpreadPower after splice: " + post.minSpreadPower);
            const newNeighbours = mergeGeohashesAndNeighbors(tempNewGeohash);
      
            const [minLat, minLng, maxLat, maxLng] = getBoundingBoxFromGeohashes(newNeighbours);
            updateData.square = [minLat, minLng, maxLat, maxLng];
            updateData.zoom = post.zoom - 1;
            updateData.newGeohash = newNeighbours;
            console.log(newNeighbours);
          }
      
          // Update the post in the database
          await updateDoc(postRef, updateData);
      
          // Fetch updated post
          const updatedPostSnapshot = await getDoc(postRef);
          const updatedPost = { id: updatedPostSnapshot.id, ...updatedPostSnapshot.data() };
      
          // Replace the old post with the updated post in the 'posts' array
          setPosts(prevPosts => prevPosts.map(post => post.id === postId ? updatedPost : post));
      
          setIsLoading(false);
        }
      };
      
      
      const handleDislike = async (postId) => {
        setIsLoading(true);
        if (!currentUserData) return;
        const postRef = doc(db, 'posts', postId);
        const postSnapshot = await getDoc(postRef);
        if (!postSnapshot.exists()) {
          console.log('No matching documents.');
          setIsLoading(false);
          return;
        }
        const post = postSnapshot.data();
        
        // Check if the user has already liked or disliked the post
        const userEmail = currentUserData.userEmail;
        const likedIndex = post.likedBy.indexOf(userEmail);
        const dislikedIndex = post.dislikedBy.indexOf(userEmail);
        
        if (likedIndex >= 0) {
          // User has already liked the post, remove from likedBy array and add to dislikedBy array
          const updatedLikedBy = post.likedBy.slice();
          updatedLikedBy.splice(likedIndex, 1);
          const updatedDislikedBy = [...post.dislikedBy, userEmail];
          
          // Update the post data
          const updateData = {
            likes: post.likes - 1,
            dislikes: post.dislikes + 1,
            likedBy: updatedLikedBy,
            dislikedBy: updatedDislikedBy
          };
          await updateDoc(postRef, updateData);
        } else if (dislikedIndex >= 0) {
          // User has already disliked the post, do nothing
          console.log('User has already disliked the post.');
          setIsLoading(false);
          return;
        } else {
          // User has not liked or disliked the post, add to dislikedBy array
          const updateData = {
            dislikes: post.dislikes + 1,
            dislikedBy: [...post.dislikedBy, userEmail]
          };
          await updateDoc(postRef, updateData);
        }
        
        // Fetch updated post
        const updatedPostSnapshot = await getDoc(postRef);
        const updatedPost = { id: updatedPostSnapshot.id, ...updatedPostSnapshot.data() };
      
        // Replace the old post with the updated post in the 'posts' array
        setPosts(prevPosts => prevPosts.map(post => post.id === postId ? updatedPost : post));
        
        setIsLoading(false);
      };


      ///////////////////////////////////////////////////////////  Helper functions start
  //////////////////////// take one geohash + neighbors and generate a new geohash
  function mergeGeohashesAndNeighbors(centerGeohashes) {
    const allGeohashes = new Set(centerGeohashes);
    const tempNeighbors = [];
  
    for (const centerGeohash of centerGeohashes) {
      const centerAndNeighbors = Geohash.neighbours(centerGeohash);
  
      for (const neighborGeohash of Object.values(centerAndNeighbors)) {
        if (!allGeohashes.has(neighborGeohash)) {
          tempNeighbors.push(neighborGeohash);
          allGeohashes.add(neighborGeohash);
        }
      }
    }
  
    return [...allGeohashes];
  }
  

  //////////////////// Get "the box" around geohash
  function getBoundingBoxFromGeohashes(geohashes) {
    if (!Array.isArray(geohashes)) {
      throw new Error('geohashes must be an array');
    }
  
    const bbox = geohashes.reduce((acc, geohash) => {
      const { lat, lon } = Geohash.decode(geohash);
      return {
        minLat: Math.min(acc.minLat, lat),
        maxLat: Math.max(acc.maxLat, lat),
        minLng: Math.min(acc.minLng, lon),
        maxLng: Math.max(acc.maxLng, lon),
      };
    }, {
      minLat: 90,
      maxLat: -90,
      minLng: 180,
      maxLng: -180,
    });
  
    const { minLat, maxLat, minLng, maxLng } = bbox;
  
    const cbox = [minLat, minLng, maxLat, maxLng];
    return cbox.flat();
  }

    return(
    <StyledDiv>
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-sm-2'>
        <p>You are following:</p>

        {currentUser && following.length > 0 ? (following.map((follow, index) => (
            <div key={index}>
              <Link to={`/user/${follow}`}>{follow}</Link>
            </div>
            ))):(<div>you are not following anyone</div>)
            }
                 
        </div>
        <div className='col-12 col-sm-10'>
        
        {postFollowing.map((post) => (
        <div className='feedItemWrapper' key={post.id}>
          <div className='userWrapper'>
            <div className='userProfileWrapper'>
              <img className="profileImg" src={post.postCreatedByUserImg} alt="profileImg"/>
              <div>
                <Link to={`/user/${post.postCreatedBy}`}><div className='userName'>{post.postCreatedByName}</div></Link>
                <div className="postDescription">
                  {post && post.description ? (post.description):(<span></span>)} 
                </div>
                <div className="tagTime">
                  <div className='postTags'>{
                    post.tags.map((tag, index)=>{
                      return (<span key={index} className="mx-1">{"#"+tag}</span>)
                    })
                  }</div>
                  <div className="time">{Math.ceil((post.timestamp.toMillis() + 600000000 - Date.now()) / (1000 * 60 * 60 * 24))} day(s) left</div>
                </div>
              </div>
            </div>
          </div>
<div className='mapAndVideoWrapper'>
<div className={showMapMobile  ? 'videoPhotoWrapperSmall' :'videoPhotoWrapper'}>
{post.videoFileName.split(".").pop().toLowerCase() !== "mp4" && post.videoFileName.split(".").pop().toLowerCase() !== "mov" ? 
(<PhotoPlayer big={showMapMobile} src={post.videoUrl} />)
:
(<VideoPlayer src={post.videoUrl} />
)}
</div>


    <div className={bigVideo ?  'smallMap' : (showMapMobile ? 'showMapMobile' : 'mapWrapper mx-3')} >
        <MapContainer center={userLocation || guestLocation} zoom={post.zoom} scrollWheelZoom={true}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Rectangle bounds={[[post.square[0], post.square[1]], [post.square[2], post.square[3]]]} />

        <Marker position={userLocation || guestLocation}>
          <Popup>
            <p>Your Location</p>
          </Popup>
        </Marker>
      </MapContainer>

      </div> 
      </div>   
      <div className='likeDislikeWrapper'>
              <div className='thumbsUp mx-3'> 
                         
                <FontAwesomeIcon onClick={()=>{handleLike(post.id)}}  
                icon={faThumbsUp} 
                size="xl"  
                className='likeDislike'
                style={{ color: (currentUserData && currentUserData.userEmail && post.likedBy.includes(currentUserData.userEmail)) ? '#0d6efd' : 'gray' }} 
    
                />
                      <span className='mx-2'>{post.likes}</span>
            </div>      
            <div className='thumbsDown mx-3'>         
                <FontAwesomeIcon onClick={()=>{handleDislike(post.id)}} 
                icon={faThumbsDown} 
                size="xl" 
                className='likeDislike'
                style={{ color: (currentUserData && currentUserData.userEmail && post.dislikedBy.includes(currentUserData.userEmail)) ? '#0d6efd' : 'gray' }}
                />
                      <span className='mx-2'> {post.dislikes}</span>
            </div>

              <CommentBox postId={post.id}/>
                  <span className='mx-2'>{post.commentCount}</span>
 
            <div className='mapIconSmallScreen mx-3'>
            <FontAwesomeIcon onClick={handleShowMapMobile} icon={faEarthAmericas} className="globe" color="gray" size="xl"/>
            </div>
      </div>         
        </div>
        
      ))}



        </div>
      </div>
    </div>
  </StyledDiv>
)
}


const StyledDiv = styled.div`

.feedItemWrapper{
  margin-bottom:20px;
}

.likeDislikeWrapper{
  display:flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.map{
  height:50% ;
}

.leaflet-container{
  height:100% ;
  width:100%;
}

.mapWrapper{
  width:100%;
  max-width:350px;
  height:350px;
  @media(max-width:1200px){
    display:none;
  }
}

.mapIconSmallScreen{
  @media(min-width:1200px){
    display:none;
  }
}

.showMapMobile{
  position:relative;
  left:0px;
  top:0px;
  width:300px;
  height:300px;

}

.smallMap{
  display:none ;
}

.mapAndVideoWrapper{
  display: flex;
  width:100% ;
}

.videoPhotoWrapper{
  width:100% ;
}

.videoPhotoWrapperSmall{
  width:50% ;
}



.userWrapper {
    display: flex;
    align-items: center;
    padding: 12px;
  }

  .userProfileWrapper {
    display: flex;
    align-items: center;
    margin-right: 12px;

    .profileImg {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 12px;
    }

    .userName {
      font-weight: bold;
      margin-bottom: 4px;
    }
  }

  .postTags {
    color: #5c5c5c;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .time{
    color: #5c5c5c;
    font-size: 12px;
  }

  .postTags span {
    margin-right: 4px;
  }

  .thumbs{
    position: relative ;
  }

  .likeDislike{
    cursor: pointer;
  }

  .likeDislike:hover{
    color:#0d6efd ;
  }

  .globe:hover{
    color:#0d6efd;
    cursor:pointer;

  }

  .postPhoto{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    margin-bottom:12px;
  }


`;
