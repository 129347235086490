import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
import DivOptions from '../components/DivOptions'
import Feed from '../components/Feed'
import { doc, updateDoc } from "firebase/firestore"; 
import { db } from "../firebase/firebase";
import { useAuth } from '../context/context'


export default function Home() {

    const [, setUserLocation] = useState(null);
    const { currentUser, currentUserData } = useAuth()

    useEffect(() => {
      if(currentUser) {
          navigator.geolocation.getCurrentPosition(
              position => {
                  setUserLocation({
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude
                  });
                  updateLatLng(position.coords.latitude, position.coords.longitude)
              },
              error => {
                  console.log(error);
              }
          );
      }
  }, [currentUser]);
  

      async function updateLatLng(lat, lng){
        if(currentUser){
                await updateDoc(doc(db, "users", currentUser.email),{
            lat: lat,
            lng: lng
        })
        }else{
          return
        }
    }
    


  return (
    <StyledDiv>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-sm-2'>
            <DivOptions className="optionsWrapper"/>
          </div>
          <div className='col-12 col-sm-10'>
            <Feed className="feedWrapper"/>

          </div>
        </div>
      </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`




`;