import React, { useRef } from 'react'
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faExpand, faCompress } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../context/context';



export default function PhotoPlayer(props) {
  const videoRef = useRef(null);

  const { bigVideo, handleBigVideo } = useAuth();

  return (
<StyledDiv>
    <div className={bigVideo ? ("bigVideo") : ("smallVideo")}> 
            <img
        ref={videoRef}
        src={props.src}
        alt="bigsmall"
        className={props.big !== true ? 'photo' : 'photoSmall'}

      />
      {/*
      <div className='photoBottomWrapper'>
         {bigVideo ? 
        (<FontAwesomeIcon icon={faCompress} color="gray" className="expand" onClick={handleBigVideo}/>)
        :
        (<FontAwesomeIcon icon={faExpand} color="gray" className="expand" onClick={handleBigVideo}/>)}
      </div>
       */}

    </div>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`

.smallVideo{
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 680px;
  max-height: 500px;
  overflow: hidden;
  margin-bottom: 15px;
}
.bigVideo{
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 680px;
  max-width: 100%;
  max-height: auto;
  overflow: hidden;
  margin-bottom: 15px;
}
 
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom:12px;

  }



  .photoBottomWrapper{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: end;


    .expand{
    margin:7px;
    cursor:pointer ;

  }
  }
  .photo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    margin-bottom:12px;
  }
  .photoSmall{
    width: 50%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    margin-bottom:12px;
  }
`;



