import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../firebase/firebase";
import { doc, getDoc, collection, query, where, getDocs, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import VideoPlayer from "../components/VideoPlayer";
import styled from 'styled-components';
import { Link } from "react-router-dom"
import { useAuth } from '../context/context';
import { MapContainer, TileLayer, Marker, Popup, Rectangle } from 'react-leaflet'
import Geohash from 'latlon-geohash';
import PhotoPlayer from "../components/PhotoPlayer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp,faComment,faEarthAmericas } from '@fortawesome/free-solid-svg-icons'
import CommentBox from "../components/CommentBox";


export default function SingleUser() {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { currentUserData, bigVideo } = useAuth();

  const [userDocument, setUserDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [stateUpdate, setStateUpdate] = useState(false)
  const [userLocation, setUserLocation] = useState(null);
  const [showMapMobile, setShowMapMobile] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);


  useEffect(() => {
    const fetchFollowingStatus = async () => {
        try {
            const followingDocRef = doc(db, 'users', currentUserData.userEmail, 'following', id);
            const followingDocSnap = await getDoc(followingDocRef);

            // If the document exists, setIsFollowing to true, else false
            setIsFollowing(followingDocSnap.exists());
        } catch (error) {
            console.error("Error fetching following status: ", error);
        }
    };

    // If currentUser and singleUser are not null, fetch following status
    if (currentUserData && id) {
        fetchFollowingStatus();
    }
}, [currentUserData, id, stateUpdate]); // This useEffect runs whenever currentUser or singleUser changes


  const handleShowMapMobile=()=>{
    setShowMapMobile(!showMapMobile)
  }

  useEffect(() => {
    const getLocation = async () => {
      if (!currentUserData) return; // Check if currentUserData is defined
      const location = [currentUserData.lat, currentUserData.lng];
      setUserLocation(location);
    }
    getLocation();
  }, [currentUserData]);

  useEffect(() => {
    setLoading(true);
    const docRef = doc(db, "users", id);
    let isCancelled = false;
    const getUser = async () => {
      const myUser = await getDoc(docRef);
      if (!isCancelled) {
        if (myUser.exists()) {
          setUserDocument(myUser.data());
          setLoading(false);
        } else {
          console.log("issue");
        }
      }
    };
    getUser();
    return () => {
      isCancelled = true;
    };
  }, [id]);

  useEffect(() => {
    const fetchPosts = async () => {
      const postsRef = collection(db, "posts");
      const q = query(postsRef, where("postCreatedBy", "==", id));
      const querySnapshot = await getDocs(q);
      const postsData = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      const sortedPostsData = postsData.sort((a, b) => b.timestamp - a.timestamp); // Sort the postsData in descending order based on timestamp
      setPosts(sortedPostsData);
    };
  
    fetchPosts();
  }, [id]);


  ///////////////////////////////////////////////////////////  Helper functions start
  //////////////////////// take one geohash + neighbors and generate a new geohash
  function mergeGeohashesAndNeighbors(centerGeohashes) {
    const allGeohashes = new Set(centerGeohashes);
    const tempNeighbors = [];
  
    for (const centerGeohash of centerGeohashes) {
      const centerAndNeighbors = Geohash.neighbours(centerGeohash);
  
      for (const neighborGeohash of Object.values(centerAndNeighbors)) {
        if (!allGeohashes.has(neighborGeohash)) {
          tempNeighbors.push(neighborGeohash);
          allGeohashes.add(neighborGeohash);
        }
      }
    }
  
    return [...allGeohashes];
  }

  //////////////////// Get "the box" around geohash
  function getBoundingBoxFromGeohashes(geohashes) {
    if (!Array.isArray(geohashes)) {
      throw new Error('geohashes must be an array');
    }
  
    const bbox = geohashes.reduce((acc, geohash) => {
      const { lat, lon } = Geohash.decode(geohash);
      return {
        minLat: Math.min(acc.minLat, lat),
        maxLat: Math.max(acc.maxLat, lat),
        minLng: Math.min(acc.minLng, lon),
        maxLng: Math.max(acc.maxLng, lon),
      };
    }, {
      minLat: 90,
      maxLat: -90,
      minLng: 180,
      maxLng: -180,
    });
  
    const { minLat, maxLat, minLng, maxLng } = bbox;
  
    const cbox = [minLat, minLng, maxLat, maxLng];
    console.log(cbox)
    return cbox.flat();
  }




  const handleLike = async (postId) => {
    setIsLoading(true);
    if (!currentUserData) return;
    const postRef = doc(db, 'posts', postId);
    const postSnapshot = await getDoc(postRef);
    if (!postSnapshot.exists()) {
      console.log('No matching documents.');
      setIsLoading(false);
      return;
    }
    const post = postSnapshot.data();
    
    // Check if the user has already liked the post
    const userEmail = currentUserData.userEmail;
    const likedIndex = post.likedBy.indexOf(userEmail);
    const dislikedIndex = post.dislikedBy.indexOf(userEmail);
    
    if (likedIndex >= 0) {
      // User has already liked the post, do nothing
      console.log('User has already liked the post.');
      setIsLoading(false);
      return;
    } else {
      const updateData = {};
      if (dislikedIndex >= 0) {
        // User has disliked the post, remove from dislikedBy array and add to likedBy array
        const updatedDislikedBy = post.dislikedBy.slice();
        updatedDislikedBy.splice(dislikedIndex, 1);
        updateData.dislikedBy = updatedDislikedBy;
        updateData.dislikes = post.dislikes - 1;
      }
      // Add user to likedBy array and increment likes
      updateData.likedBy = [...post.likedBy, userEmail];
      updateData.likes = post.likes + 1;
  
      // Calculate the current spread power based on the number of likes and dislikes
      const spreadPower = updateData.likes - (updateData.dislikes || post.dislikes);
  
      // Increment the spread power
      updateData.spreadPower = spreadPower + 1;
  
      if (post.geohash.length >= 1 && spreadPower >= post.minSpreadPower[0]) {
        const tempNewGeohash = post.newGeohash;
        console.log("minSpreadPower before splice: " + post.minSpreadPower);
        updateData.spreadPower = 0;
        const newSpreadPower = post.minSpreadPower.slice(1);
        updateData.minSpreadPower = newSpreadPower;
        console.log("minSpreadPower after splice: " + post.minSpreadPower);
        const newNeighbours = mergeGeohashesAndNeighbors(tempNewGeohash);
  
        const [minLat, minLng, maxLat, maxLng] = getBoundingBoxFromGeohashes(newNeighbours);
        updateData.square = [minLat, minLng, maxLat, maxLng];
        updateData.zoom = post.zoom - 1;
        updateData.newGeohash = newNeighbours;
        console.log(newNeighbours);
      }
  
      // Update the post in the database
      await updateDoc(postRef, updateData);
  
      // Fetch updated post
      const updatedPostSnapshot = await getDoc(postRef);
      const updatedPost = { id: updatedPostSnapshot.id, ...updatedPostSnapshot.data() };
  
      // Replace the old post with the updated post in the 'posts' array
      setPosts(prevPosts => prevPosts.map(post => post.id === postId ? updatedPost : post));
  
      setIsLoading(false);
    }
  };
  
  
  const handleDislike = async (postId) => {
    setIsLoading(true);
    if (!currentUserData) return;
    const postRef = doc(db, 'posts', postId);
    const postSnapshot = await getDoc(postRef);
    if (!postSnapshot.exists()) {
      console.log('No matching documents.');
      setIsLoading(false);
      return;
    }
    const post = postSnapshot.data();
    
    // Check if the user has already liked or disliked the post
    const userEmail = currentUserData.userEmail;
    const likedIndex = post.likedBy.indexOf(userEmail);
    const dislikedIndex = post.dislikedBy.indexOf(userEmail);
    
    if (likedIndex >= 0) {
      // User has already liked the post, remove from likedBy array and add to dislikedBy array
      const updatedLikedBy = post.likedBy.slice();
      updatedLikedBy.splice(likedIndex, 1);
      const updatedDislikedBy = [...post.dislikedBy, userEmail];
      
      // Update the post data
      const updateData = {
        likes: post.likes - 1,
        dislikes: post.dislikes + 1,
        likedBy: updatedLikedBy,
        dislikedBy: updatedDislikedBy
      };
      await updateDoc(postRef, updateData);
    } else if (dislikedIndex >= 0) {
      // User has already disliked the post, do nothing
      console.log('User has already disliked the post.');
      setIsLoading(false);
      return;
    } else {
      // User has not liked or disliked the post, add to dislikedBy array
      const updateData = {
        dislikes: post.dislikes + 1,
        dislikedBy: [...post.dislikedBy, userEmail]
      };
      await updateDoc(postRef, updateData);
    }
    
    // Fetch updated post
    const updatedPostSnapshot = await getDoc(postRef);
    const updatedPost = { id: updatedPostSnapshot.id, ...updatedPostSnapshot.data() };
  
    // Replace the old post with the updated post in the 'posts' array
    setPosts(prevPosts => prevPosts.map(post => post.id === postId ? updatedPost : post));
    
    setIsLoading(false);
  };

  const handleFollowUser = async (id) => {
    if (!currentUserData) return;
  
    try {
      // Get references to the users in Firestore
      const currentUserRef = doc(db, 'users', currentUserData.userEmail);
      const userToFollowRef = doc(db, 'users', id);
  
      // Get references to the 'following' subcollection of the current user and the 'followers' subcollection of the user to follow
      const currentUserFollowingRef = collection(currentUserRef, 'following');
      const userToFollowFollowersRef = collection(userToFollowRef, 'followers');
  
      // Create the documents in the subcollections
      await setDoc(doc(currentUserFollowingRef, id), { id: id });
      await setDoc(doc(userToFollowFollowersRef, currentUserData.userEmail), { id: currentUserData.userEmail });
    } catch (error) {
      console.error('Error following user: ', error);
    }
    setStateUpdate(!stateUpdate)
  };

  const handleUnfollowUser = async (id) => {
    if (!currentUserData) return;

    try {
      // Get references to the users in Firestore
      const currentUserRef = doc(db, 'users', currentUserData.userEmail);
      const userToUnfollowRef = doc(db, 'users', id);

      // Get references to the 'following' subcollection of the current user and the 'followers' subcollection of the user to unfollow
      const currentUserFollowingRef = doc(currentUserRef, 'following', id);
      const userToUnfollowFollowersRef = doc(userToUnfollowRef, 'followers', currentUserData.userEmail);

      // Delete the documents in the subcollections
      await deleteDoc(currentUserFollowingRef);
      await deleteDoc(userToUnfollowFollowersRef);
    } catch (error) {
      console.error('Error unfollowing user: ', error);
    }
    setStateUpdate(!stateUpdate)
};
  



const defaultUserBannerImg = "https://firebasestorage.googleapis.com/v0/b/postemic.appspot.com/o/defaultMedia%2Fdefault-banner.jpg?alt=media&token=2bffde7b-9f67-400a-bfb4-df0975785cf7"
const defaultUserImg = "https://firebasestorage.googleapis.com/v0/b/postemic.appspot.com/o/defaultMedia%2FuserDefault.png?alt=media&token=c028efb3-0056-401a-8f2d-e2b3aa8d0dc4"

  return (

    <StyledDiv className='container'>

    <div className="row">
    <div className='col-12 col-sm-2'>
          
          </div>
          <div className='col-12 col-sm-10'>
      <div className="col bannerWrapper">
        {/* Hero image full width */}
        {userDocument && userDocument.userBannerImg ? 
        (<img src={userDocument.userBannerImg} alt=" bammer" className="banner" />)
        :
        (<img src={defaultUserBannerImg} alt="banner" className="banner" />)
        }
        {/* Small circle profile image in left corner */}
        {userDocument && userDocument.userImg ?
        (<img src={userDocument.userImg} alt="userImg" className="userImg" />)
        :
        (<img src={defaultUserImg} alt="userImg" className="userImg" />)
        }
      </div>
      <div className="row justify-content-between">
      <div className="col-4 mx-5 my-2">
          {/* Follow/unfollow button to the right */}
     {/* User name/userEmail */}
     {!loading && userDocument && userDocument.userName ? 
          (<h3>{userDocument.userName}</h3>)
          : loading ? 
          (<p>Loading...</p>) : (<p>Nameless user</p>)}        
          </div>        
          <div className="col-4 mx-5 my-2">
          {/* Follow/unfollow button to the right */}
          {isFollowing ? 
    <button className="followButton" onClick={()=>{handleUnfollowUser(id)}}>Unfollow</button> :
    <button className="followButton" onClick={()=>{handleFollowUser(id)}}>Follow</button>
}        </div>
      </div>

    </div>
    </div>


        <div className='row'>
          <div className='col-12 col-sm-2'>
          
          </div>
          <div className='col-12 col-sm-10'>

          {posts.map((post) => (
        <div className='feedItemWrapper' key={post.id}>
          <div className='userWrapper'>
            <div className='userProfileWrapper'>
              <img className="profileImg" src={post.postCreatedByUserImg} alt="userImg"/>
              <div>
                <Link to={`/user/${post.postCreatedBy}`}><div className='userName'>{post.postCreatedByName}</div></Link>
                <div className="postDescription">
                  {post && post.description ? (post.description):(<span></span>)} 
                </div>
                <div className="tagTime">
                  <div className='postTags'>{
                    post.tags.map((tag, index)=>{
                      return (<span key={index} className="mx-1">{"#"+tag}</span>)
                    })
                  }</div>
                  <div className="time">{new Date(post.timestamp.seconds * 1000 + post.timestamp.nanoseconds / 1000000).toLocaleString()}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='mapAndVideoWrapper'>
<div className={showMapMobile  ? 'videoPhotoWrapperSmall' :'videoPhotoWrapper'}>
{post.videoFileName.split(".").pop().toLowerCase() !== "mp4" && post.videoFileName.split(".").pop().toLowerCase() !== "mov" ? 
(<PhotoPlayer big={showMapMobile} src={post.videoUrl} />)
:
(<VideoPlayer src={post.videoUrl} />
)}
</div>


    <div className={bigVideo ?  'smallMap' : (showMapMobile ? 'showMapMobile' : 'mapWrapper mx-3')} >
        <MapContainer center={userLocation} zoom={post.zoom} scrollWheelZoom={true}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Rectangle bounds={[[post.square[0], post.square[1]], [post.square[2], post.square[3]]]} />

        <Marker position={userLocation}>
          <Popup>
            <p>Your Location</p>
          </Popup>
        </Marker>
      </MapContainer>

      </div> 
      </div>   
      <div className='likeDislikeWrapper'>
              <div className='thumbsUp mx-3'> 
                         
                <FontAwesomeIcon onClick={()=>{handleLike(post.id)}}  
                icon={faThumbsUp}  
                size="xl"  
                className='likeDislike'
                style={{ color: (currentUserData && currentUserData.userEmail && post.likedBy.includes(currentUserData.userEmail)) ? '#0d6efd' : 'gray' }} 

                />
                      <span className='mx-2'>{post.likes}</span>
            </div>      
            <div className='thumbsDown mx-3'>         
                <FontAwesomeIcon onClick={()=>{handleDislike(post.id)}} 
                icon={faThumbsDown} 
                size="xl" 
                className='likeDislike'
                style={{ color: (currentUserData && currentUserData.userEmail && post.dislikedBy.includes(currentUserData.userEmail)) ? '#0d6efd' : 'gray'  }}
                />
                      <span className='mx-2'> {post.dislikes}</span>

            </div>
            <CommentBox postId={post.id}/>
                    <span className='mx-2'>{post.commentCount}</span>
            <div className='mapIconSmallScreen mx-3'>
            <FontAwesomeIcon onClick={handleShowMapMobile} icon={faEarthAmericas} className="globe" color="gray" size="xl"/>
            </div>
      </div>         
        </div>
        
      ))}




     
          </div>
        </div>
      </StyledDiv>
      
 
  );
}


const StyledDiv = styled.div`
.postDescription{
  overflow-wrap: break-word;  
    word-wrap: break-word; 
    word-break: break-word;
  }
.bannerWrapper{
  position:relative ;
}

.banner{
  position: relative; 
  max-height:300px;
  width: 80%; 
  margin: 0 auto; 
  overflow: hidden;
  object-fit: cover; 
  border-radius:2%;
  margin:10px;

}

.userImg{
  position:absolute ; /* Position relative to the .banner element */
  bottom:1px;
  left:1px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #fff;
}
.followButton{
  background-color: #1DA1F2;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.likeDislikeWrapper{
  display:flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.map{
  height:50% ;
}

.leaflet-container{
  height:100% ;
  width:100%;
}

.mapWrapper{
  width:100%;
  max-width:350px;
  height:350px;
  @media(max-width:1200px){
    display:none;
  }
}

.mapIconSmallScreen{
  @media(min-width:1200px){
    display:none;
  }
}

.showMapMobile{
  position:relative;
  left:0px;
  top:0px;
  width:300px;
  height:300px;

}

.smallMap{
  display:none ;
}

.mapAndVideoWrapper{
  display: flex;
  width:100% ;
}

.videoPhotoWrapper{
  width:100% ;
}

.videoPhotoWrapperSmall{
  width:50% ;
}



.userWrapper {
    display: flex;
    align-items: center;
    padding: 12px;
  }

  .userProfileWrapper {
    display: flex;
    align-items: center;
    margin-right: 12px;

    .profileImg {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 12px;
    }

    .userName {
      font-weight: bold;
      margin-bottom: 4px;
    }
  }

  .postTags {
    color: #5c5c5c;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .time{
    color: #5c5c5c;
    font-size: 12px;
  }

  .postTags span {
    margin-right: 4px;
  }

  .thumbs{
    position: relative ;
  }

  .likeDislike{
    cursor: pointer;
  }

  .likeDislike:hover{
    color:#0d6efd ;
  }

  .globe:hover{
    color:#0d6efd;
    cursor:pointer;

  }

  .postPhoto{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    margin-bottom:12px;
  }

`;
