import React, { useContext, useRef, useState } from 'react';

const PlayingVideoContext = React.createContext();

export function usePlayingVideo() {
  return useContext(PlayingVideoContext);
}

export function PlayingVideoProvider({ children }) {
  const playingVideoRef = useRef(null);
  const [volume, setVolume] = useState(localStorage.getItem('videoVolume') || 0.5);
  const [isMuted, setIsMuted] = useState(false);





  const saveVolume = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    localStorage.setItem('videoVolume', newVolume); // Save to local storage to persist
    
    // If there's a video currently playing, update its volume as well
    if (playingVideoRef.current) {
      playingVideoRef.current.volume = newVolume;
    }
  };

  const toggleMute = () => {
    // toggling the muted state
    const newState = !isMuted;
    setIsMuted(newState);
  
    // Apply the muted state to the current video if available
    if (playingVideoRef.current) {
      playingVideoRef.current.muted = newState;
    }
  };

  const setPlayingVideo = (video) => {
    if (playingVideoRef.current && playingVideoRef.current !== video) {
      playingVideoRef.current.pause();
    }
    playingVideoRef.current = video;
  };

  const value = {
                setPlayingVideo,
                volume, 
                saveVolume,
                toggleMute,
                isMuted,
                setIsMuted
}
  return (
    <PlayingVideoContext.Provider value={
        value
        }>
      {children}
    </PlayingVideoContext.Provider>
  );
}
