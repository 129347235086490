import React from 'react';

export default function Error() {

  return (
    <div style={errorStyle}>
      <h1>404</h1>
      <h2>Oops! Page not found</h2>
      <p>We are sorry but the page you are looking for does not exist.</p>
      <a href="/"> <button>Back to Home</button></a>
    </div>
  );
}

// Optional: Add some styles for the error page
const errorStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  textAlign: 'center',
};
