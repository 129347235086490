import React from 'react'
import styled from 'styled-components';
import { Link } from "react-router-dom"
import { useAuth } from '../context/context';


export default function DivOptions() {
  const { currentUserData } = useAuth();
  
  return (
    <StyledDiv>
                <Link to="/about" className='link'><div className='someContent'>About</div></Link>
                <Link to="/explore" className='link'><div className='someContent'>Explore</div></Link>

    {currentUserData ? 
    (<>
      <Link to="/following" className='link'> <div className='someContent'>Following</div>  </Link>
        <Link to="/followers" className='link'><div className='someContent'>Followers</div>   </Link>
        </>)
    :
    (<span></span>)
    }
        
        
    
    </StyledDiv>
  )
}


const StyledDiv = styled.div`


.someContent{
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.someContent:hover{
  color: black;
    background-color:rgb(221 221 221 / 35%) ;
}
@media(max-width:769px){
     .someContent{
      display:none
     }
  }

  .link{
    text-decoration:none;
  }


`
