import React, {useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/context';

export default function SecureRoute({children}){
    const { currentUser, loading } = useAuth();

    // If the user data is still being fetched, return null or a loading spinner
    if (loading) {
      return null; // Or return a loading spinner
    }

    return currentUser && currentUser.email === "postemic@postemicman.com" ? children : <Navigate to="/" />;
}
