import React, {useRef, useState} from 'react'
import { Card, Form, Button, Alert, Spinner  } from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from '../context/context'
import { db  } from "../firebase/firebase"
import { collection } from 'firebase/firestore';
import { getAuth   } from "firebase/auth";
import styled from 'styled-components'





export default function ForgotPassword() {

    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const auth = getAuth();



    const userCollectionRef = collection(db,"users")

    async function handleSubmit(e){
        e.preventDefault()
  
        try{    
                setMessage("")
                setError("")
                setLoading(true)
                   await resetPassword(emailRef.current.value)
                   setMessage("Check your inbox for password reset instructions")
                  
        } catch{
            setError("Failed to reset password")
        }
        setLoading(false)
    }


  return (
    <StyledDiv>
    <div className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
    <div className="w-100" style={{maxWidth: "400px"}}> 
    <Card>
        <Card.Body>
            <h2 className='text-center mb-4'>Reset Password</h2>
            {error && <Alert variant='danger'>{error}</Alert>}
            {message && <Alert variant='success'>{message}</Alert>}

            <Form onSubmit={handleSubmit}>
                <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
                </Form.Group>
               
               
                {!loading  ? ( <Button disabled={loading} type="submit" className='w-100 text-center'>Reset Password</Button>)
                :
  (<Button disabled={loading} className='w-100 text-center'>
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    Loading...
  </Button>
)}


            </Form>
        </Card.Body>
    </Card>

   
    <div className='w-100 text-center mt-2'>
    <Link to="/login">
                Login
    </Link>
        </div>
        </div>
        </div>
  
        </StyledDiv>
  )
}


const StyledDiv = styled.div`


`
