import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, Popup } from 'react-leaflet';
import L from 'leaflet';
import styled from "styled-components"


const coloredIcon = color => new L.DivIcon({
    className: 'custom-icon',
    html: `
        <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 0C7.163 0 0 7.163 0 16a16 16 0 0016 16 16 16 0 0016-16C32 7.163 24.837 0 16 0zm0 3c-.896 0-1.723.155-2.512.43a3.994 3.994 0 01-1.383-1.125A12.93 12.93 0 0016 3zm0 29C8.28 32 2 25.72 2 18H0l3.5-4L7 18H5c0 6.065 4.935 11 11 11s11-4.935 11-11h2l3.5 4L29 18h-2c0 7.72-6.28 14-14 14zm0-25c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zm0 14a6 6 0 100-12 6 6 0 000 12z" fill="${color}"/>
        </svg>`,
    iconSize: [32, 32],
    iconAnchor: [16, 32], // tip of the icon will point to the marker location
    popupAnchor: [0, -32]
});

const LocationPicker = ({ onLocationSelect }) => {
    const [position, setPosition] = useState(null);

    const LocationMarker = ({ position, setPosition }) => {
        useMapEvents({
            click: (e) => {
                setPosition(e.latlng);
                onLocationSelect(e.latlng);
            },
        });
        
        return position ? (
            <Marker position={position}>
                <Popup>
                    Selected Location
                </Popup>
            </Marker>
        ) : null;
    };
    
    
    
    const [adminLocation, setAdminLocation] = useState(null);  // Initialize to null for clarity
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getLocation = () => {
            navigator.geolocation.getCurrentPosition(
              position => {
                setAdminLocation([position.coords.latitude, position.coords.longitude]);
                setLoading(false);
              },
              error => {
                console.error("Error fetching location: ", error);
                setLoading(false);  // Ensure we set loading to false even on error
              },
              { timeout: 10000 } // example option: give up after 10 seconds
            );
        };
        getLocation();
      }, []);
    

    const center = !loading && adminLocation ? adminLocation : [51.505, -0.09];


    return (
        <StyledDiv style={{ width: '100%', height: '400px' }}>

        {loading ? (<p>loading...</p>) : (
            <MapContainer center={center} zoom={9} style={{ width: '100%', height: '400px' }}>
    <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    />
    <LocationMarker position={position} setPosition={setPosition} />
    <Marker position={center}>
        <Popup>
            <p>Your Location</p>
        </Popup>
    </Marker>

    {position && (
        <Marker position={position} icon={coloredIcon('#FF0000')}>
    <Popup>
        Selected Location
    </Popup>
</Marker>


    )}
</MapContainer>
 
        ) }
      

        </StyledDiv>
    );
}

export default LocationPicker;


const StyledDiv = styled.div`

.leaflet-div-icon {
    background: transparent;
    border: none;
}

`