import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import 'firebase/compat/storage';
import 'firebase/compat/functions';




const app = firebase.initializeApp({

    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_DATABASEURL
})

export const storage = firebase.storage(app);
export const db = getFirestore(app);
export const gAuth = getAuth(app);
export const auth = app.auth()
export const func = firebase.functions(app)
export default app

