import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "./pages/Home"
import Nav from "./components/Nav"
import Signup from "./pages/Signup";
import Login from "./pages/Login"
import UserSettings from "./pages/UserSettings"
import {AuthProvider} from "./context/context";
import PrivateRoute from "./components/PrivateRoute";
import RedirectRoute from "./components/RedirectRoute"
import ForgotPassword from "./pages/ForgotPassword";
import UpdateProfile from "./pages/UpdateProfile";
import Upload from "./pages/Upload";
import SingleUser from "./pages/SingleUser";
import Following from "./pages/Following";
import Followers from "./pages/Followers";
import About from "./pages/About";
import Error from "./pages/Error";
import ExplorePage from "./pages/ExplorePage";
import { PlayingVideoProvider } from "./context/PlayingVideoContext";
import SinglePost from "./pages/SinglePost";
import Admin from "./pages/Admin";
import SecureRoute from "./components/SecureRoute";

function App() {

  return (
    <AuthProvider>
    <PlayingVideoProvider>
    <div className="App">

    <Router>
    <Nav/>
      <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/signup" element={<Signup/>} />
      <Route path="/login" element={<Login/>} />
      <Route path="/usersettings" element= { <PrivateRoute> <UserSettings/> </PrivateRoute>  }  />
      <Route path="/updateprofile" element= { <PrivateRoute> <UpdateProfile/> </PrivateRoute>  }  />
      <Route path="/forgotPassword" element={<ForgotPassword/>} />
     {/* <Route path="/upload" element={  <RedirectRoute> <Upload/>  </RedirectRoute>   } />*/}
      <Route path="/upload" element={  <Upload/>   } />
      <Route path="/user/:email" element={<SingleUser/>} exact  />
      <Route path="/:id" element={<SinglePost/>} exact  />

      <Route path="/following" element={ <Following/> } />
      <Route path="/followers" element={ <Followers/> } />
      <Route path="/about" element={ <About/> } />
      <Route path="/explore" element={ <ExplorePage/> } />

      <Route path="/admin" element={<SecureRoute> <Admin/> </SecureRoute>  } />
      
      <Route path="*" element={<Error/>} />
      </Routes>
    </Router>

    </div>
    </PlayingVideoProvider>
    </AuthProvider>
  );
}

export default App;
