import React from 'react'
import styled from 'styled-components';


export default function About() {
  return (
    <Container>
    <Title>About Postemic</Title>
    <Paragraph>Welcome to Postemic. We're an app built on the simple idea that local content can be meaningful and engaging.</Paragraph>
    <Paragraph>Here's how it works: when you share content on Postemic, it is initially visible only to those within a specific geographical radius. If your post receives a significant number of positive reactions, its visibility radius increases, allowing more people to see it. A well-received post can potentially expand its reach across wider regions.</Paragraph>

    <Title>Your Data & Privacy</Title>
    <Paragraph>When you sign up for Postemic, we collect and store your geographical location and email address. These details enable the functionality of our app and help us deliver a localized content experience. You can further enhance your profile by adding a username, profile picture, and a banner.</Paragraph>
    <Paragraph>As of now, your data is used strictly for the purpose of running Postemic and improving your user experience.</Paragraph>

    <Title>Content and Retention</Title>
    <Paragraph>Postemic allows you to upload video files (in MP4 or MOV formats) and photos. To ensure efficient storage and smoother playback, videos are compressed upon upload and the original files are discarded.</Paragraph>
    <Paragraph>To keep content fresh and relevant, all posts and related comments are kept on the platform for about seven days. After this period, they are permanently deleted.</Paragraph>

    <Title>Moderation</Title>
    <Paragraph>Currently, our platform relies on automated systems for moderation, with occasional oversight from our developers to maintain a positive user environment.</Paragraph>

    <Title>Content Guidelines</Title>
    <Paragraph>Postemic is intended for users aged 13 and above. Users are expected not to post any content that's inappropriate for individuals below this age.</Paragraph>

    <Title>Acceptance of Terms</Title>
    <Paragraph>By registering and using Postemic, you agree to abide by these terms. Your continued use of Postemic signifies your acceptance of any updates or changes to these terms.</Paragraph>
  </Container>
  )
}


const Container = styled.div`
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h2`
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

