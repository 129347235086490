import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../firebase/firebase'
import {  doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from "../firebase/firebase"




const AuthContext = React.createContext()


export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()
    const [currentUserData, setCurrentUserData] = useState()
    const [loading, setLoading] = useState(true)
    const [imageChanged, setImageChanged] = useState(false)
    const [bannerImageChanged, setBannerImageChanged] = useState(false)
    const [useEffectToggle, setUseEffectToggle] = useState(false)
    const [bigVideo, setBigVideo] = useState(false)



    const handleBigVideo = () => {
        setBigVideo(!bigVideo);
      };

    function signup(email,password){
       return auth.createUserWithEmailAndPassword(email, password)
    }

    function login(email,password){
            return auth.signInWithEmailAndPassword(email,password)
    }
     
    function logout(){
        return auth.signOut()
    }

    function resetPassword(email){
        return auth.sendPasswordResetEmail(email)
    }

     function updateEmail(email){
        return currentUser.updateEmail(email)
        //not active, why would a user change email? change account instead!

    }

     function updatePassword(password){
        return currentUser.updatePassword(password)
    }

    async function updateUserImg(url){
        await updateDoc(doc(db, "users", currentUser.email),{
            userImg: url
        })
    }
    
    async function updateUserBannerImg(url){
        await updateDoc(doc(db, "users", currentUser.email),{
            userBannerImg: url
        })
    }

    async function getCurrentUserData(user){

        if(user){
            const docUserRef = doc(db,"users",user.email)
            const docSnap = await getDoc(docUserRef)
                if (docSnap.exists()) {
                 // Include uid in the setCurrentUserData object
                setCurrentUserData({...docSnap.data(), uid: user.uid});

                } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            }}else{
                return
            }
            }




    useEffect(()=>{
       const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
            getCurrentUserData(user)



        })
        return () => unsubscribe();
    },[imageChanged, bannerImageChanged, useEffectToggle])






    const value = {
        currentUser,
        currentUserData,
        signup,
        loading,
        login,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        updateUserImg,
        updateUserBannerImg,
        setImageChanged,
        imageChanged,
        bannerImageChanged,
        setBannerImageChanged,
        useEffectToggle, 
        setUseEffectToggle,
        handleBigVideo,
        bigVideo,
    

    }

  return (
    <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
  )
}
